export const TYPE_URL = 'url'
export const TYPE_PAGE = 'page'
export const TYPE_LIST = 'list'

export const types = {
  TYPE_URL,
  TYPE_PAGE,
  TYPE_LIST
}

export default class MenuItem {
  constructor (options) {
    const {
      label = {},
      url = null,
      type = null,
      items = [],
      pageId = null,
      disabled = false,
      trackingName = null,
      icon = null,
    } = options || {}

    this.label = label
    this.disabled = disabled
    this.items = items
    this.url = url
    this.pageId = pageId
    this.type = type
    this.trackingName = trackingName
    this.icon = icon
  }


}
