const app = state => state.app

const device = state => state.app.device

const currentUser = state => state.app.currentUser
const isAuthenticated = state => state.app.isAuthenticated

const sidebar = state => state.app.sidebar
const menuitems = state => state.menu.items

export {
  currentUser,
  isAuthenticated,
  app,
  device,
  sidebar,
  menuitems
}

