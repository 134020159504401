import VDataTable from 'vuetify/es5/components/VDataTable/VDataTable'
import { getSlot } from 'vuetify/lib/util/helpers'
import draggable from 'vuedraggable'

export default {
  extends: VDataTable,
  props: {
    draggable: { type: Boolean, default: false },
    draggableElement: { type: String, default: 'tr' }
  },
  methods: {
    genBody (props) {
      const data = {
        ...props,
        expand: this.expand,
        headers: this.computedHeaders,
        isExpanded: this.isExpanded,
        isMobile: this.isMobile,
        isSelected: this.isSelected,
        select: this.select
      }

      if (this.$scopedSlots.body) {
        return this.$scopedSlots.body(data)
      }

      if (this.draggable) {
        return this.$createElement(draggable, {
          on: {
            change: (e) => this.dragHandler(e),
            input: (value) => this.changeHandler(value)
          },
          props: {
            value: this.items,
            tag: 'tbody',
            handle: this.draggableElement
          },
          attrs: {
            handle: this.draggableElement
          }
        }, [
          getSlot(this, 'body.prepend', data, true),
          this.genItems(props.items, props),
          getSlot(this, 'body.append', data, true)
        ])
      }

      return this.$createElement('tbody', [
        getSlot(this, 'body.prepend', data, true),
        this.genItems(props.items, props),
        getSlot(this, 'body.append', data, true)
      ])
    },
    dragHandler (e) {
      if (!e.moved) {
        return
      }
      this.$emit('moved', { newIndex: e.moved.newIndex, oldIndex: e.moved.oldIndex })
    },
    changeHandler (items) {
      this.$emit('change', items)
    }
  }
}
