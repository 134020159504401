<template>
  <v-expansion-panel class="embed-constructor-item">
    <v-expansion-panel-header>
      <div @click.stop class="embed-constructor-item__head">
        <div class="embed-constructor-item__sorting">
          <v-btn fab x-small @click="$emit('prev')" style="box-shadow: none">
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>

          <v-btn fab x-small @click="$emit('next')" style="box-shadow: none">
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </div>

        <div class="text-body-2">{{ title }}</div>

        <div class="embed-constructor-item__head-aside">
          <v-btn @click="cancel" title="Удалить" x-small fab style="box-shadow: none">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <embed-booking-link-constructor
        :value="value"
        @input="$emit('input', $event)"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import EmbedBookingLinkConstructor from './EmbedBookingLinkConstructor.vue'

export default {
  components: {
    EmbedBookingLinkConstructor,
  },
  props: {
    value: {
      type: Object,
    }
  },
  computed: {
    title () {
      return this?.value?.service?.name || 'Новая услуга'
    }
  },
  methods: {
    cancel () {
      if (confirm('Подтвердите удаление')) {
        this.$emit('remove')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.embed-constructor-item__head {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: minmax(0, auto) 1fr minmax(0, auto);
  align-items: center;
  grid-gap: 20px;
}

.embed-constructor-item__head-aside {
  margin-right: 20px;
}

.embed-constructor-item__sorting {
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 4px;
}
</style>
