<template>
  <v-uploader
    class="images-uploader"
    v-bind="props"
    v-on="$listeners"
  />
</template>
<script>
import config from '../../../config'
import VUploader from './VUploader.vue'

export default {
  components: {
    VUploader
  },
  computed: {
    props () {
      const options = this.$attrs?.options || {}
      return {
        ...this.$attrs,
        options: {
          url: config.photoUploadUrl,
          acceptedFiles: 'image/*',
          maxFilesize: 15,
          ...options,
        }
      }
    }
  }
}
</script>
