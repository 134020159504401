import Quill from 'quill'
const Block = Quill.import('blots/block')

class Preface extends Block {
  static create () {
    const node = super.create()
    return node
  }

  handler (value) {
    this.quill.format('preface', Boolean(value), Quill.sources.USER)
  }
}

Preface.blotName = 'preface'
Preface.className = 'ql-preface'
Preface.tagName = 'div'

export default Preface
