import api from '../utils/api'
import omit from "lodash/omit";
import get from "lodash/get";

export default {
  get (serviceId) {
    return api.get(`services/${serviceId}`)
  },
  getAll (params) {
    return api.get('services', { params: params })
  },
  getPrices (params) {
    return api.get('services/price', { params, limit: 9999 })
  },
  getOnlyPrice (params = {}) {
    return api.get('services/only-price', { params })
  },
  update (serviceId, service) {
    return api.patch(`services/${serviceId}`, service)
  },
  create (service) {
    return api.post('services', service)
  },
  remove (serviceId) {
    return api.delete(`services/${serviceId}`)
  },
  toDto (entity) {
    let n = omit(entity, ['procedures', 'blogPosts'])
    n.procedureIds = entity.procedures.map(p => p.id)
    n.blogPostIds = entity.blogPosts.map(({ uuid }) => uuid)
    return n
  }
}
