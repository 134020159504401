export default class LimitMaxLength {
  constructor(quill, options) {
    this.limit = options.numberSymbols
    this.quill = quill
    this.quill.on('text-change', () => {
      if (this.quill.getLength() > this.limit) {
        this.quill.deleteText(this.limit, this.quill.getLength());
      }
    })
  }
}