import Quill from 'quill'

let BlockEmbed = Quill.import('blots/block/embed')

class ImageFigure extends BlockEmbed {
  static create (value) {
    let node = super.create()

    let link = document.createElement('A')
    let img = document.createElement('img')
    img.setAttribute('src', value.url)
    link.setAttribute('href', value.url)
    link.setAttribute('target', '_blank')

    link.appendChild(img)
    node.appendChild(link)

    if (typeof value.caption !== void 0 && value.caption) {
      let figcaption = document.createElement('figcaption')
      figcaption.textContent = value.caption

      node.appendChild(figcaption)
    }

    if (typeof value.credit !== void 0 && value.credit) {
      let cite = document.createElement('cite')
      cite.textContent = value.credit

      node.appendChild(cite)
    }

    return node
  }

  static value (domNode) {
    let img = domNode.querySelector('img')
    let caption = domNode.querySelector('figcaption')
    let credit = domNode.querySelector('credit')

    return {
      url: img.getAttribute('src'),
      caption: caption ? caption.textContent : null,
      credit: credit ? credit.textContent : null
    }
  }

  format (name, value) {
    console.log('format', name, value)
  }
}

ImageFigure.blotName = 'image'
ImageFigure.className = 'image'
ImageFigure.tagName = 'figure'

export default ImageFigure
