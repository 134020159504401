export default {
  functional: true,
  props: ['page'],
  render (h, { props, parent }) {
    const isCurrent = props.page === parent.page
    return (
      <li>
        { isCurrent
          ? h('a', { class: 'pagination-link is-current is-disabled' }, [ props.page ])
          : h('a', {
            on: {
              click: e => {
                e.preventDefault()
                parent.change({ page: props.page })
              }
            },
            class: 'pagination-link'
          }, [ props.page ])
        }
      </li>
    )
  }
}
