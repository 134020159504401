import Quill from 'quill'
const Block = Quill.import('blots/block/embed')

class Iframe extends Block {
  static create (iframeCode) {
    const node = super.create()
    node.classList.add('ql-iframe-embed')
    node.innerHTML = iframeCode
    return node
  }
  static value(domNode) {
    return null
  }
}

Iframe.blotName = 'iframe'
Iframe.tagName = 'div'

export default Iframe
