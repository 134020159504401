<template>
  <choose-modal
    :visible="visible"
    :closable="false"
    :title="title"
  >
    <validation-observer
      v-slot="{ handleSubmit }"
    >
      <form @submit.prevent="handleSubmit(submit)">
        <validation-provider v-slot="{ errors }" :rules="{ required: true }">
          <v-text-field
            autofocus
            v-model="form.name"
            placeholder="Название"
            :error-messages="errors"
          />
        </validation-provider>
        <validation-provider v-slot="{ errors }" :rules="{ required: true }">
          <v-text-field
            v-model="form.group"
            placeholder="Группа Workplace"
            :error-messages="errors"
          />
        </validation-provider>
        <validation-provider v-slot="{ errors }" :rules="{ required: true }">
          <v-text-field
            v-model="form.label"
            placeholder="Метка"
            :error-messages="errors"
          />
        </validation-provider>
        <div class="d-flex justify-space-between">
          <v-btn type="submit" color="success">
            Сохранить
          </v-btn>
          <v-btn color="error" @click="close">
            Закрыть
          </v-btn>
        </div>
      </form>
    </validation-observer>
  </choose-modal>
</template>

<script>
import ChooseModal from "../../components/elements/modals/ChooseModal"

export default {
  components: {
    ChooseModal,
  },
  props: {
    visible: Boolean,
    title: String,
    claim: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      form: {
        name: null,
        group: null,
        label: null,
        ...this.claim
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
      this.visible = false
    },
    submit () {
      this.$emit('choose', this.form)
      this.visible = false
      // this.$destroy()
    }
  }
}
</script>
