<template>
  <list-editor-light v-model="innerValue">
    <template v-slot:content="{ item }">
      <menu-editor-list-item :item="item" />
    </template>

    <template #dialog="{ model, input }">
      <menu-editor-form :value="model" @input="input" />
    </template>
  </list-editor-light>
</template>

<script>
import isEqual from 'lodash/isEqual'
import ListEditorLight from '@/components/ListEditor/ListEditorLight.vue'
import MenuEditorListItem from '@/components/Menu/MenuEditorListItem.vue'
import MenuEditorForm from '@/components/Menu/MenuEditorForm.vue'

export default {
  components: {
    MenuEditorListItem,
    ListEditorLight,
    MenuEditorForm,
  },
  model: {
    prop: 'items',
    event: 'input'
  },
  props: {
    items: {
      type: Array,
      default: () => ({})
    }
  },
  data () {
    return {
      innerValue: this.items.slice()
    }
  },
  watch: {
    items (items) {
      if (isEqual(this.innerValue, items)) return
      this.innerValue = items
    },
    innerValue (innerValue) {
      this.$emit('input', innerValue)
    }
  }
}
</script>
