<template>
  <div>
    <nprogress-container></nprogress-container>
    <div v-if="loading">loading...</div>
    <router-view
      v-else
      :key="$route.path"
      class="animated"
    />
  </div>
</template>

<script>
  import NprogressContainer from 'vue-nprogress/src/NprogressContainer'

  export default {
    components: {
      NprogressContainer
    },
    computed: {
      loading () {
        return this.$store.state.app.authLoading
      }
    }
  }
</script>

<style lang="scss">
  @import '~animate.css';
  //@import '~bulma/css/bulma.css';
  @import '~element-ui/lib/theme-chalk/index.css';
  @import '~vue-multichat/dist/VueChat.css';
  @import '~@riophae/vue-treeselect/dist/vue-treeselect.css';
  @import '~vue-multiselect/dist/vue-multiselect.min.css';
  @import '~assets/styles/preloader';
  @import "~assets/styles/variables";
  @import "~assets/styles/colors";
  @import "~assets/styles/input";
  @import "~assets/styles/dialog";
  @import "~assets/styles/animations";
  @import '~bulma/sass/utilities/variables';

  .animated {
    animation-duration: .377s;
  }

  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/scss/font-awesome';

  .multiselect__option {
    display: flex !important;
  }

  fieldset.box {
    border: 0 none;
    legend.label {
      background: #fff;
    }
  }

  .multiselect {
    &.is-danger {
      .multiselect__tags {
        border-color: #ff3860 !important;
      }
    }
  }

  select {
    .is-danger {
      border-color: #ff3860 !important;
    }
  }

  .tab-content {
    /*z-index: 9999;*/
    /*overflow: visible !important;*/
  }

  .clickable {
    color: #4a4a4a;
    /*text-decoration: underline;*/

    &:hover {
      color: #363636;
      cursor: pointer;
    }
  }

  .nprogress-container {
    position: fixed !important;
    width: 100%;
    height: 50px;
    z-index: 2048;
    pointer-events: none;

    #nprogress {
      $color: #F44336;

      .bar {
        background: $color;
      }
      .peg {
        box-shadow: 0 0 10px $color, 0 0 5px $color;
      }

      .spinner-icon {
        border-top-color: $color;
        border-left-color: $color;
      }
    }
  }

  .table-responsive {
    display: block;
    width: 100%;
    min-height: .01%;
    overflow-x: auto;
  }

  .is-published {
    background: hsla(171, 100%, 41%, 0.1);
  }

  table {
    .is-success {
      background: lighten( $green, 20% );
    }
    .is-warning {
      background: lighten( $yellow, 20% );
    }
  }

  .v-data-table {
    &__mobile-row {
      min-height: 30px !important;
    }
  }

  .control.is-horizontal {
    > .control {
      span {
        align-items: center;
        display: inline-flex;
        height: 32px;
        padding-left: 8px;
      }
      &__checkbox {
        flex-direction: row;
        align-self: center;
      }
    }
  }

  .level {
    .level-item {
      > .label {
        margin: 0 5px 0 0 !important;
      }
    }
  }

  .field.is-grouped {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
    justify-content: flex-start;

    > .control {
      input[type=checkbox] {
        margin-top: 0.8em;
      }
    }

    > .control.is-expanded {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
      flex-grow: 1;
        -ms-flex-negative: 1;
      flex-shrink: 1;
    }

    > .control:not(:last-child) {
      margin-bottom: 0;
      margin-right: 0.75rem;
    }

    > .control {
        -ms-flex-negative: 0;
      flex-shrink: 0;
    }

    .flatpickr-calendar.static.open {
      z-index: 10000;
    }
  }

  /*.cv-wrapper {*/
    /*height: auto !important;*/
    /*min-height: auto !important;*/
  /*}*/

  .cv-event.work {
    background-color: lightblue;
  }
  .cv-event.education {
    background-color: lightyellow;
  }
  .cv-event.vacation {
    background-color: lightgreen;
  }

  .el-form-item__content {
    line-height: normal;
  }

  .create-update-page, .list-page {
    position: relative;
    margin: -20px;
    .main-container {
      padding: 20px;
      .actions_bar {
        margin-bottom: 15px;
      }
      .list-items {
        margin-bottom: 15px;
      }
    }
    .sub-navbar {
      height: 50px;
      line-height: 50px;
      position: relative;
      width: 100%;
      text-align: right;
      padding-right: 20px;
        -webkit-transition: position .6s ease;
      transition: position .6s ease;
      background: #f3f3f3;
    }
  }

  .v-input--selection-controls {
    margin-top: 4px !important;
  }
</style>
