<template>
  <div class="order-arrows">
    <v-btn
      v-for="btn in buttons"
      :key="btn.name"
      @click="$emit(btn.name)"
      fab
      x-small
      depressed
      :disabled="btn.disabled"
    >
      <v-icon small>{{ btn.icon }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    last: {
      type: Boolean,
      default: false
    },
    first: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttons () {
      return [
        {
          name: 'prev',
          icon: 'fa-angle-up',
          disabled: this.disabled || this.first
        },
        {
          name: 'next',
          icon: 'fa-angle-down',
          disabled: this.disabled || this.last
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.order-arrows {
  display: flex;
  grid-gap: 4px;
}
</style>
