<template>
  <validation-observer tag="div" class="locale-field">
    <div class="locale-field__label" v-if="label">
      {{ label }}
    </div>
    <div class="locale-field__items">
      <validation-provider
        :rules="rules"
        slim
        :key="locale"
        v-for="(locale, idx) in locales"
        :name="`${name}_${locale}`"
        v-slot="{ errors }"
      >
        <component
          :is="component"
          :error-messages="errors[0]"
          v-model="innerValue[locale]"
          v-bind="{
            ...$attrs,
            autofocus: idx === 0 ? $attrs.autofocus : false,
            label: locale
          }"
        />
      </validation-provider>
    </div>
  </validation-observer>
</template>

<script>
import { VTextField, VTextarea } from 'vuetify/lib'
import { locales } from '@/store/modules/app'

export default {
  components: {
    VTextField,
    VTextarea
  },
  props: {
    rules: {
      type: [String, Object]
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    component: {
      type: [String, Object],
      required: true
    },
    value: {
      required: true,
      validator: (prop) => typeof prop === 'object' || prop === null
    }
  },
  data () {
    const value = this.value || {}
    return {
      locales,
      innerValue: locales.reduce((acc, locale) => {
        return {
          ...acc,
          [locale]: value[locale] || null
        }
      }, {})
    }
  },
  watch: {
    innerValue: {
      deep: true,
      handler (innerValue) {
        this.$emit('input', innerValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.locale-field__label {
  margin-bottom: 4px;
}
.locale-field__items {
  & > * {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
