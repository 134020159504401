import Vue from 'vue'
import Router from 'vue-router'
import menuModule from '../store/modules/menu'
import lazyLoading from '../store/modules/menu/lazyLoading'

Vue.use(Router)

export default new Router({
  mode: 'history',
  linkActiveClass: 'is-active',
  scrollBehavior: (from, to, savedPosition) => {
    if (from.path !== to.path) {
      return { y: 0 }
    }
    return false
  },
  routes: [
    // public
    {
      name: 'Login',
      path: '/login',
      meta: { requiresAuth: false },
      component: lazyLoading('Login', false)
    },
    {
      path: '/feedback',
      meta: { requiresAuth: false },
      component: lazyLoading('BlankMain', false),
      children: [
        {
          path: ":id/approve",
          component: lazyLoading('feedback/Approve', false),
        }
      ]
    },
    // private
    {
      path: '/',
      component: lazyLoading('Main', false),
      meta: {
        requiresAuth: true
      },
      children: [ ...generateRoutesFromMenu(menuModule.state.items) ]
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

// Menu should have 2 levels.
function generateRoutesFromMenu (menu = [], routes = []) {
  for (let i = 0, l = menu.length; i < l; i++) {
    let item = menu[i]
    if (item.path) {
      routes.push(item)
    }
    if (!item.component) {
      generateRoutesFromMenu(item.children, routes)
    }
  }
  return routes
}
