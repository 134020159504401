<template>
  <v-card class="page-header" :class="sticky ? 'page-header--sticky' : ''">
    <v-card-title>
      <v-layout row class="align-center px-4">
        <slot name="inner">
          <div class="page-header-left">
              <h3 class="pr-3" v-if="title">{{ title }}</h3>
              <h4 class="subtitle-1" v-if="subtitle">{{ subtitle }}</h4>
          </div>
          <v-spacer></v-spacer>
          <div class="page-header-right">
              <slot name="right"></slot>
          </div>
        </slot>
      </v-layout>
    </v-card-title>
    <v-card-text v-if="$scopedSlots.bottom">
      <slot name="bottom"></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    sticky: { type: Boolean, default: false },
    title: { type: String, required: true },
    subtitle: { type: String, required: false }
  }
}
</script>

<style lang="stylus">
.page-header-left
    h3
        font-size: 2rem;
        font-weight: 500;

.page-header--sticky
  position: sticky;
  top: 52px;
  z-index 99;
</style>
