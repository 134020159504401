import AuthUser, {
  ROLE_UNREGISTERED_CLIENT,
  ROLE_CLIENT,
  ROLE_GUEST_EMPLOYEE,
  ROLE_EMPLOYEE,
  ROLE_EDITOR_EMPLOYEE,
  ROLE_HR_EMPLOYEE,
  ROLE_IDDQD,
  ROLE_DOCTOR_EMPLOYEE,
  ROLE_AGENTS_ADMIN_EMPLOYEE,
  ROLE_COLLAB,
  ROLE_ADMIN_EMPLOYEE,
  ROLE_ADMIN_BUSINESS_COLLAB,
  ROLE_BUSINESS_COLLAB,
  ROLE_CHIEF_DOCTOR_EMPLOYEE
} from '../models/AuthUser'
import api from '../utils/api'

export const PATIENT_ROLES = [
  ROLE_UNREGISTERED_CLIENT,
  ROLE_CLIENT
]

export const EMPLOYEE_ROLES = [
  ROLE_GUEST_EMPLOYEE,
  ROLE_EDITOR_EMPLOYEE,
  ROLE_EMPLOYEE,
  ROLE_DOCTOR_EMPLOYEE,
  ROLE_ADMIN_EMPLOYEE,
  ROLE_CHIEF_DOCTOR_EMPLOYEE,
  ROLE_HR_EMPLOYEE,
  ROLE_AGENTS_ADMIN_EMPLOYEE,
  ROLE_IDDQD
]

export const ROLES = [
  ...PATIENT_ROLES,
  ...EMPLOYEE_ROLES
]

export const COLLAB_ROLES = [
  ROLE_COLLAB,
  ROLE_BUSINESS_COLLAB,
  ROLE_ADMIN_BUSINESS_COLLAB
]

export const ROLE_LABELS = {
  [ROLE_UNREGISTERED_CLIENT]: 'Пациент (регистрация не завершена)',
  [ROLE_CLIENT]: 'Пациент',
  [ROLE_GUEST_EMPLOYEE]: 'Сотрудник (гость)',
  [ROLE_EDITOR_EMPLOYEE]: 'Редактор',
  [ROLE_EMPLOYEE]: 'Сотрудник',
  [ROLE_DOCTOR_EMPLOYEE]: 'Доктор',
  [ROLE_CHIEF_DOCTOR_EMPLOYEE]: 'Главный врач',
  [ROLE_ADMIN_EMPLOYEE]: 'Админ',
  [ROLE_HR_EMPLOYEE]: 'HR',
  [ROLE_AGENTS_ADMIN_EMPLOYEE]: 'Админ мед. проф.',
  [ROLE_IDDQD]: 'Супер админ',
  [ROLE_COLLAB]: 'Базовая роль',
  [ROLE_BUSINESS_COLLAB]: 'Сотрудник организации',
  [ROLE_ADMIN_BUSINESS_COLLAB]: 'Администратор'
}

export function formatRoleLabel (role) {
  return ROLE_LABELS[role] || role
}

export default {
  roles: ROLES,
  collabRoles: COLLAB_ROLES,
  roleLabels: ROLE_LABELS,
  whoami () {
    return api.get('auth/whoami').then(resp => {
      return new AuthUser(resp)
    })
  },
  login (redirect = null) {
    let url = 'api/v1/auth/connect' + (redirect ? '?redirect=' + redirect : '')
    if (process.env.VUE_APP_API_URL) {
      url = process.env.VUE_APP_API_URL + '/' + url
    }
    location.href = url
  },
  logout () {
    return api.post('auth/logout')
  }
}
