// copy from https://github.com/vuikit/vuikit

import range from 'lodash/range'
import render from './render'

export default {
  name: 'pagination',
  render,
  props: {
    // displayed page
    page: {
      type: Number,
      default: 1
    },
    // total pages
    totalPages: {
      validator: function (value) {
        return value == null || Number.isInteger(value)
      },
      required: true
    },
    limit: {
      type: Number,
      default: 10
    },
    // amount of pages around current one
    pageRange: {
      type: Number,
      default: 3
    }
  },
  computed: {
    prevPage () {
      return (this.page - 1) !== 0 ? this.page - 1 : null
    },
    nextPage () {
      return (this.page + 1) <= this.totalPages ? this.page + 1 : null
    },
    mainPages () {
      let start = this.page - this.pageRange
      let end = this.page + this.pageRange
      if (end > this.totalPages) {
        end = this.totalPages
        start = this.totalPages - this.pageRange * 2
        start = start < 1 ? 1 : start
      }
      if (start <= 1) {
        start = 1
        end = Math.min(this.pageRange * 2 + 1, this.totalPages)
      }
      return range(start, end + 1)
    },
    prePages () {
      return range(1,
        this.mainPages[0] <= 3
          ? this.mainPages[0]
          : 2
      )
    },
    postPages () {
      const mainLast = this.mainPages[this.mainPages.length - 1]
      return range(
        mainLast >= this.totalPages - 2
          ? mainLast + 1
          : this.totalPages,
        this.totalPages + 1
      )
    }
  },
  methods: {
    change (changes) {
      const state = {
        page: this.page,
        totalPages: this.totalPages,
        limit: this.limit
      }
      // event
      this.$emit('change', Object.assign(state, changes))
    }
  }
}
