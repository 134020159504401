import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import Claim from '../formats/Claim'
import { Quill } from 'vue2-editor'
import ClaimModal from 'views/modals/ClaimModal'

const ClaimModalComponent = Vue.extend(ClaimModal)

function showModal (claim) {
  return new ClaimModalComponent({
    el: document.createElement('div'),
    vuetify: vuetify,
    propsData: {
      title: 'Заявка',
      visible: true,
      claim
    }
  })
}

export default class ClaimModule {
  static register () {
    let icons = Quill.import('ui/icons')
    Quill.register({ 'formats/claim': Claim })
    icons['claim'] = '<span title="Claim"><span class="fa fa-plus"></span></span>'
  }

  constructor (quill) {
    this.quill = quill
    this.toolbar = quill.getModule('toolbar')

    this.toolbar.addHandler('claim', (value) => {
      if (value) {
        this.create()
      } else {
        this.quill.format('claim', false, Quill.sources.USER)
      }
    })

    this.quill.root.addEventListener('click', (e) => {
      const blot = Quill.find(e.target)
      const isClaim = blot instanceof Claim
      if (!isClaim) {
        return
      } else {
        let { claim } = blot.value() || {}
        this.edit(claim)
      }
    })
  }

  create () {
    const range = this.quill.getSelection()
    showModal().$once('choose', (data) => {
      this.quill.insertEmbed(range.index, 'claim', data, Quill.sources.USER)
    })
  }

  edit (claim) {
    const range = this.quill.getSelection()
    const index = range.index - 1
    showModal(claim).$once('choose', (data) => {
      this.quill.deleteText(index, 1)
      this.quill.insertEmbed(index, 'claim', data, Quill.sources.USER)
    })
  }
}
