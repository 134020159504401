<template>
  <div>
    <locale-field
      component="v-text-field"
      rules="required"
      name="label"
      autofocus
      label="Название пункта меню"
      v-model="item.label"
      outlined
      dense
      hide-details
    />

    <v-checkbox
      v-model="item.disabled"
      class="mt-2"
      label="Исключить из меню"
    />


    <validation-provider
      rules="required"
      name="type"
      slim
      v-slot="{ errors }"
    >
      <v-select
        dense
        outlined
        hide-details
        class="mt-4"
        label="Тип"
        :error-messages="errors"
        v-model="item.type"
        name="type"
        item-text="text"
        item-value="value"
        @input="onInputType"
        :items="[
          { text: 'Список', value: 'list' },
          { text: 'Ссылка', value: 'url' },
          { text: 'Страница', value: 'page' },
        ]"
      />
    </validation-provider>

    <validation-provider
      slim
      name="url"
      rules="required"
      v-slot="{ errors }"
      mode="lazy"
      v-if="item.type === TYPE_URL"
    >
      <v-text-field
        class="mt-4"
        name="url"
        label="Адрес"
        placeholder="https://example.com"
        outlined
        dense
        :error-messages="errors"
        hide-details
        v-model="item.url"
      />
    </validation-provider>

    <validation-provider
      slim
      name="page"
      rules="required"
      mode="lazy"
      v-slot="{ errors }"
      v-if="item.type === TYPE_PAGE"
    >
      <page-select
        outlined
        dense
        hide-details
        class="mt-4"
        label="Страница"
        :error-messages="errors"
        placeholder="Выберите страницу"
        v-model="item.pageId"
      />
    </validation-provider>

    <menu-editor
      class="mt-4"
      v-if="item.type === TYPE_LIST"
      v-model="item.items"
    />

    <v-text-field
      outlined
      dense
      hide-details
      class="mt-12"
      v-model="item.icon"
      label="Иконка"
      placeholder="Название иконки"
    />

    <validation-provider name="trackingName" rules="required" v-slot="{ errors }">
      <v-text-field
        outlined
        dense
        hide-details
        name="trackingName"
        class="mt-4"
        v-model="item.trackingName"
        label="Метка"
        :error-messages="errors"
        placeholder="Метка для метрики"
      />
    </validation-provider>

  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import MenuItem, {
  TYPE_URL,
  TYPE_PAGE,
  TYPE_LIST
} from '@/models/MenuItem.js'
import PageSelect from '@/components/Page/PageSelect.vue'
import LocaleField from '@/components/Locale/LocaleField.vue'

export default {
  components: {
    PageSelect,
    LocaleField,
    MenuEditor: () => import('@/components/Menu/MenuEditor.vue'),
  },
  props: {
    value: {
      type: Object,
    }
  },
  data () {
    return {
      item: new MenuItem(this.value),
      TYPE_URL,
      TYPE_PAGE,
      TYPE_LIST,
    }
  },
  watch: {
    value: {
      deep: true,
      handler (value) {
        if (isEqual(this.item, value)) return
        this.item = new MenuItem(value)
      }
    },
    item: {
      deep: true,
      handler (item) {
        this.$emit('input', item)
      }
    }
  },
  methods: {
    onInputType (type) {
      let url = null
      let pageId = null
      let items = []

      if (type === TYPE_LIST) {
        items = this.item.items
      }
      if (type === TYPE_PAGE) {
        pageId = this.item.pageId
      }
      if (type === TYPE_URL) {
        url = this.item.url
      }

      Object.assign(this.item, {
        _type: type,
        url,
        pageId,
        items,
      })
    }
  }
}
</script>
