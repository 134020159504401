var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('item-content',{attrs:{"item":item}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('item-content',{attrs:{"item":item}})]}}])},'v-select',Object.assign({}, _vm.$attrs,
    {items: _vm.items,
    itemText: 'text',
    itemValue: 'id'}),false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }