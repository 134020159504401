import Vue from "vue"
import PhotoModal from "../views/modals/PhotoModal"
import Vuetify from "vuetify"

const PhotoModalComponent = Vue.extend(PhotoModal)

export default {
  methods: {
    openPhotoModal (title = 'Выбор фотографии', visible = true) {
      return new PhotoModalComponent({
        el: document.createElement('div'),
        propsData: { title, visible },
        vuetify: new Vuetify()
      })
    }
  }
}
