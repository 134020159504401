export class EmbedBookingLink {
  constructor (options) {
    this.clinic = options.clinic || null
    this.doctor = options.doctor || null
    this.service = options.service || null
  }
}

export class EmbedBooking {
  constructor (options) {
    let version = options.version || 2
    let items = options.items || []
    this.label = options.label
    this.version = version
    this.items = items.map((item) => new EmbedBookingLink(item))
  }
}
