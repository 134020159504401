import Page from './Page'
// import Button from './Button'

export default function (h) {
  return this.totalPages > 0 ? (
    <nav class={{ 'pagination': true }}>
      <ul class={{ 'pagination-list': true }}>
        { this.prePages.map(page => h(Page, { props: {page} })) }
        { (this.mainPages[0] > (this.prePages.length + 1)) &&
          (<li><span class="pagination-ellipsis">&hellip;</span></li>) }
        { this.mainPages.map(page => h(Page, { props: {page} })) }
        { ((this.mainPages[this.mainPages.length - 1] + 1) < this.postPages[0]) &&
          (<li><span class="pagination-ellipsis">&hellip;</span></li>) }
        { this.postPages.map(page => h(Page, { props: {page} })) }
      </ul>
    </nav>
  ) : null
}
