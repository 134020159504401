<template>
  <items-order-provider v-model="innerValue" v-slot="{ prev, next }">
    <div class="le">

      <slot v-bind="{ ...scope, prev, next }">
        <div class="le__list-top">
          <v-btn @click="create" :disabled="isFilled" color="primary">
            <v-icon left x-small>fa-plus</v-icon>
            <span>Добавить</span>
          </v-btn>
        </div>

        <slot name="list" v-bind="{ prev, next, ...scope }">
          <ul class="le__list">
            <li class="le__list-item" v-for="(item, idx) in innerValue" :key="item.key">
              <div class="le__list-item-ordering">
                <order-arrows
                  v-if="sortable"
                  :last="idx === innerValue.length - 1"
                  :first="idx === 0"
                  @prev="prev(idx)"
                  @next="next(idx)"
                />
              </div>
              <slot name="item" v-bind="{ item, idx, ...scope }">
                <div class="le__list-item-content">
                  <slot name="content" v-bind="{ item, idx, ...scope }"></slot>
                </div>
                <div class="le__list-item-actions">
                  <v-btn v-if="canEdit" @click="() => edit(idx)" depressed fab x-small><v-icon small>fa-pencil</v-icon></v-btn>
                  <v-btn @click="() => remove(idx)" depressed fab x-small><v-icon small>fa-trash</v-icon></v-btn>
                </div>
              </slot>
            </li>
          </ul>
        </slot>
      </slot>

      <v-dialog v-bind="dialogOptions" v-model="state.dialog" @click:outside="cancel">
        <div v-if="state.dialog">
          <div class="d-flex justify-space-between mb-4">
            <div class="text-h5">
              {{ dialogTitle || (state.model && state.model.id ? 'Редактирование записи' : 'Новая запись') }}
            </div>
            <v-btn @click="cancel" icon class="ml-auto"><v-icon>mdi-close</v-icon></v-btn>
          </div>
          <validation-observer ref="observer" v-slot="validation">
            <slot name="dialog" v-bind="{
              ...scope,
              validation
            }" />
          </validation-observer>
          <div class="d-flex justify-space-between mt-8">
            <slot name="actions" v-bind="scope">
              <v-btn @click="submit" :disabled="!state.model" color="success">
                Сохранить
              </v-btn>
              <v-btn @click="cancel">
                Отмена
              </v-btn>
            </slot>
          </div>
        </div>
      </v-dialog>
    </div>
  </items-order-provider>
</template>

<script>
import isEqual from 'lodash/isEqual'
import ItemsOrderProvider from '../ItemsOrderProvider.vue'
import OrderArrows from '../OrderArrows.vue'

export const MODE_EDIT = 'edit'
export const MODE_CREATE = 'create'
export const MODE_LIST = 'list'
export const APPEND_DIR_START = 'start'
export const APPEND_DIR_END = 'end'

const createItem = (item) => ({ key: Math.random(), ...item })
const mapItems = (items) => items.map(createItem)

const STATE_EDIT = (idx, model) => ({
  mode: MODE_EDIT,
  idx,
  model,
  dialog: true
})

const STATE_CREATE = () => ({
  idx: null,
  mode: MODE_CREATE,
  model: null,
  dialog: true
})

const STATE_LIST = () => ({
  idx: null,
  mode: MODE_LIST,
  dialog: false,
  model: null
})

export default {
  components: {
    ItemsOrderProvider,
    OrderArrows
  },
  model: {
    prop: 'items',
    event: 'input'
  },
  props: {
    limit: {
      type: Number,
      default: Infinity
    },
    appendTo: {
      type: String,
      default: APPEND_DIR_END,
      validator: (prop) => [APPEND_DIR_START, APPEND_DIR_END].includes(prop)
    },
    sortable: {
      type: Boolean,
      default: true
    },
    dialogTitle: {
      type: String
    },
    dialogOptions: {
      type: Object,
      default: () => ({})
    },
    items: {
      type: Array,
      default: () => ([])
    },
    confirmText: {
      type: [String, Function],
      default: 'Really?'
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      state: STATE_LIST(),
      innerValue: mapItems(this.items),
    }
  },
  computed: {
    itemsLeft () {
      const hasLimit = this.limit < Infinity
      return hasLimit ? this.limit - this.items.length : Infinity
    },
    isFilled () {
      return this.items.length >= this.limit
    },
    scope () {
      const methods = [
        'input',
        'submit',
        'validate',
        'create',
        'edit',
        'remove',
        'cancel',
        'update',
      ].reduce((acc, key) => {
        return { ...acc, [key]: this[key].bind(this) }
      }, {})
      return {
        itemsLeft: this.itemsLeft,
        isFilled: this.isFilled,
        list: this.innerValue,
        model: this.state.model,
        ...methods,
      }
    }
  },
  watch: {
    items: {
      deep: true,
      handler (items) {
        if (isEqual(items, this.innerValue)) return
        this.innerValue = mapItems(items)
      }
    },
    innerValue: {
      deep: true,
      handler (innerValue) {
        this.$emit('input', innerValue)
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.observer.validate()
    },
    create () {
      if (this.isFilled) return
      this.state = STATE_CREATE()
    },
    update (idx, item) {
      this.$set(this.innerValue, idx, item)
    },
    push (item) {
      const model = createItem(item)
      const method = {
        [APPEND_DIR_START]: 'unshift',
        [APPEND_DIR_END]: 'push'
      }[this.appendTo]
      this.innerValue[method](model)
    },
    async submit () {
      const valid = await this.validate()
      if (!valid) return
      const { idx, model, mode } = this.state
      if (mode === MODE_CREATE) {
        this.push(model)
      }
      if (mode === MODE_EDIT) {
        this.update(idx, model)
      }
      this.state = STATE_LIST()
    },
    edit (idx) {
      if (!this.canEdit) return
      const model = this.innerValue[idx]
      this.state = STATE_EDIT(idx, model)
    },
    remove (idx) {
      const text = typeof this.confirmText === 'function' ? this.confirmText() : this.confirmText
      if (!confirm(text)) return
      this.innerValue.splice(idx, 1)
    },
    cancel () {
      this.state = STATE_LIST()
    },
    input (value) {
      this.state.model = value
    }
  }
}
</script>

<style lang="scss" scoped>
.le__list {
  padding-left: 0;
}

.le__list-top {
  margin-bottom: 12px;
}
.le__list-item {
  display: flex;
  grid-gap: 16px;
  border-bottom: 1px solid #eee;
  padding: 8px 0;
  &:last-child {
    margin-bottom: 0;
  }
  & > * {
    flex: 0 0 auto;
  }
}

.le__list-item-actions {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  margin-left: auto;
  margin-right: 0;
}

.le__list-item-content {
  flex: 1 1 auto;
}
</style>
