export const ROLE_UNREGISTERED_CLIENT = 'ROLE_UNREGISTERED_CLIENT'
export const ROLE_CLIENT = 'ROLE_CLIENT'

export const ROLE_GUEST_EMPLOYEE = 'ROLE_GUEST_EMPLOYEE'
export const ROLE_EDITOR_EMPLOYEE = 'ROLE_EDITOR_EMPLOYEE'
export const ROLE_EMPLOYEE = 'ROLE_EMPLOYEE'
export const ROLE_DOCTOR_EMPLOYEE = 'ROLE_DOCTOR_EMPLOYEE'
export const ROLE_CHIEF_DOCTOR_EMPLOYEE = 'ROLE_CHIEF_DOCTOR_EMPLOYEE'
export const ROLE_ADMIN_EMPLOYEE = 'ROLE_ADMIN_EMPLOYEE'
export const ROLE_HR_EMPLOYEE = 'ROLE_HR_EMPLOYEE'
export const ROLE_AGENTS_ADMIN_EMPLOYEE = 'ROLE_AGENTS_ADMIN_EMPLOYEE'
export const ROLE_IDDQD = 'ROLE_IDDQD'

export const ROLE_COLLAB = 'ROLE_COLLAB'
export const ROLE_BUSINESS_COLLAB = 'ROLE_BUSINESS_COLLAB'
export const ROLE_ADMIN_BUSINESS_COLLAB = 'ROLE_ADMIN_BUSINESS_COLLAB'
export const ROLE_UNREGISTERED_COLLAB = 'ROLE_UNREGISTERED_COLLAB'

export const ROLE_ALLOWED_TO_SWITCH = 'ROLE_ALLOWED_TO_SWITCH'

export const ROLES = {
  ROLE_UNREGISTERED_CLIENT,
  ROLE_CLIENT,
  ROLE_GUEST_EMPLOYEE,
  ROLE_EDITOR_EMPLOYEE,
  ROLE_EMPLOYEE,
  ROLE_DOCTOR_EMPLOYEE,
  ROLE_CHIEF_DOCTOR_EMPLOYEE,
  ROLE_ADMIN_EMPLOYEE,
  ROLE_HR_EMPLOYEE,
  ROLE_AGENTS_ADMIN_EMPLOYEE,
  ROLE_IDDQD,
}

export const ROLES_LIST = Object.values(ROLES)

function intersects (a, b) {
  return b.filter((item) => a.includes(item))
}


class AuthUser {
  constructor (dto = {}) {
    this.uuid = dto.uuid || null
    this.username = dto.username || 'anonymous'
    this.roles = dto.roles || []
    this.fullName = dto.fullName || 'anonymous',
    this.externalId = dto.externalId || null
  }

  getUuid () {
    return this.uuid
  }

  getRoles () {
    return this.roles
  }

  getUsername () {
    return this.username
  }

  getFullName () {
    return this.fullName
  }

  getExternalId() {
    return this.externalId
  }

  isPatient () {
    return this.roles.indexOf(ROLE_CLIENT) > -1 || this.roles.indexOf(ROLE_UNREGISTERED_CLIENT) > -1
  }

  isAdmin () {
    return this.roles.indexOf(ROLE_ADMIN_EMPLOYEE) > -1
  }

  isEditor () {
    return this.roles.indexOf(ROLE_EDITOR_EMPLOYEE) > -1
  }

  isHR () {
    return this.roles.indexOf(ROLE_HR_EMPLOYEE) > -1
  }

  isDoctor () {
    return this.roles.indexOf(ROLE_DOCTOR_EMPLOYEE) > -1
  }

  isChiefDoctor () {
    return this.roles.indexOf(ROLE_CHIEF_DOCTOR_EMPLOYEE) > -1
  }

  isAgentsAdmin () {
    return this.roles.indexOf(ROLE_AGENTS_ADMIN_EMPLOYEE) > -1
  }

  isGod () {
    return this.roles.indexOf(ROLE_IDDQD) > -1
  }

  canLoginToAccount () {
    return this.roles.indexOf(ROLE_ALLOWED_TO_SWITCH) > -1
  }

  hasRoles (roles) {
    return Boolean(intersects(this.roles, roles).length)
  }
}

export default AuthUser
