import { Quill } from 'vue2-editor'
import ImageFigure from './formats/ImageFigure'
import BookingEmbed from './formats/BookingEmbed'
import ClaimModule from './modules/Claim'
import FrenchQuotes from './formats/FrenchQuotes'
import EnglishQuotes from './formats/EnglishQuotes'
import SanitizeClipboard from './modules/SanitizeClipboard'
import BookingModule from './modules/Booking'
import LimitMaxLengthModule from './modules/LimitMaxLength'
import IframeEmbed from './formats/IframeEmbed'
import Preface from './formats/Preface'

export default class Bootstrap {
  static init () {
    let icons = Quill.import('ui/icons')
    icons['header'][3] = '<span style="font-size: 12px">H3</span>'
    icons['header'][4] = '<span style="font-size: 12px">H4</span>'
    icons['french-quotes'] = '<span>«»</span>'
    icons['english-quotes'] = '<span>“”</span>'
    icons['booking'] = '<span title="Booking"><span class="fa fa-calendar"></span></span>'
    icons['iframe'] = '<span title="Iframe"><span class="fa fa-code"></span></span>'
    icons['preface'] = '<span title="Вводный текст"><span class="fa fa-flag"></span></span>'

    Quill.register({
      'formats/preface': Preface,
      'formats/booking': BookingEmbed,
      'formats/image': ImageFigure,
      'formats/iframe': IframeEmbed,
      'formats/french-quotes': FrenchQuotes,
      'formats/english-quotes': EnglishQuotes,
      'modules/clipboard': SanitizeClipboard,
      'modules/booking': BookingModule,
      'modules/claim': ClaimModule,
      'modules/limit': LimitMaxLengthModule,
    }, true)

    // Quill.debug('info')
  }
}
