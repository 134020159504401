<template>
    <v-text-field
      v-model="cmpValue"
      v-bind:label="label"
      v-bind:placeholder="placeholder"
      v-bind:readonly="readonly"
      v-bind:disabled="disabled"
      v-bind:outlined="outlined"
      v-bind:dense="dense"
      v-bind:hide-details="hideDetails"
      v-bind:error="error"
      v-bind:error-messages="errorMessages"
      v-bind:rules="rules"
      v-bind:clearable="clearable"
      v-bind:backgroundColor="backgroundColor"
      type="text"
    />
</template>

<script>
// TODO: Fix two-way binding
import accounting from 'accounting'

export default {
  data () {
    return {
      viewValue: 0
    }
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: [Boolean, String],
      default: false
    },
    errorMessages: {
      type: [Array, String],
      default: () => []
    },
    rules: {
      type: [Array, String],
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: "white"
    },
    precision: {
      type: Number,
      default: 2
    },
    thousand: {
      type: String,
      default: ','
    },
    decimal: {
      type: String,
      default: '.'
    }
  },
  computed: {
    cmpValue: {
      get: function() {
        return this.formatValueToString(this.value / 100)
      },
      set: function(newValue) {
        this.updateValue(newValue)
      }
    },
  },
  methods: {
    formatValueToString: function (value) {
      let fixedValue
      let hasThousandSymbol
      let hasZeroAfterThousandSymbol

      value = value === null ? 0 : value

      if (typeof value === 'string') {
        hasThousandSymbol = value.indexOf(this.thousand) > -1
        fixedValue = accounting.unformat(value, this.thousand)

        if (this.precision > 1) {
          let split = fixedValue.toString().split('.')
          fixedValue = split[0] + (split[1] ? '.' + split[1].substr(0, this.precision) : '')
        }
      } else {
        fixedValue = value
        hasThousandSymbol = value % 1 !== 0
      }

      hasZeroAfterThousandSymbol = value.toString().indexOf(this.thousand + '0') > -1

      let remainderStr = fixedValue.toString().split('.')[1]
      let precision = (!remainderStr) ? 0 : (remainderStr.length >= this.precision ? this.precision : remainderStr.length)

      if (precision === 0 && hasZeroAfterThousandSymbol) {
        precision = 1
      }

      return accounting.formatNumber(
        fixedValue,
        precision,
        this.decimal,
        this.thousand
      ) + (hasThousandSymbol && this.precision > 1 && !precision ? this.thousand : '')
    },
    updateValue: function (value) {
      let formattedValue = this.formatValueToString(value)
      let unformattedValue = accounting.unformat(formattedValue, this.thousand)
      this.$emit('input', parseInt(unformattedValue * 100, 10))
    },
    selectAll: function (event) {
      // Workaround for Safari bug
      // http://stackoverflow.com/questions/1269722/selecting-text-on-focus-using-jquery-not-working-in-safari-and-chrome
      setTimeout(function () {
        event.target.select()
      }, 0)
    }
  }
}
</script>
