import sanitizeHtml from 'sanitize-html'

export default {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'figure', 'img' ]),
  allowedAttributes: {
    '*': [ 'title' ],
    'a': [ 'href' ],
    'img': [ 'src' ],
    'figure': [ 'class' ]
  }
}
