import BaseService from './baseService'
import omit from "lodash/omit";
import get from "lodash/get";
import orderBy from 'lodash/orderBy'
import { ItemMeta } from '../models'

class PageService extends BaseService {
  get url () {
    return 'pages'
  }

  async getAll (params) {
    const data = await super.getAll(...arguments)
    data.items = orderBy(data.items, ['enabled'], ['desc'])
    return data
  }

  createModel () {
    return {
      name: null,
      enabled: true,
      robots: [],
      title: {},
      description: {},
      uri: null,
      body: {},
      keywords: null,
      priority: 0,
      openGraphImage: null,
      openGraphTwitterCard: null,
      openGraphTitle: {},
      openGraphDescription: {},
      meta: new ItemMeta(),
    }
  }
  toDto (entity) {
    let n = omit(entity, ['openGraphImage'])
    n.openGraphImageId = get(entity, 'openGraphImage.id', null)
    return n
  }
}

export default new PageService()

