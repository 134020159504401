import isEqual from 'lodash/isEqual'

export function createFormComponent ({
  alias = 'form',
  ctor,
  modelProp = 'value',
  modelEvent = 'input',
}) {
  return {
    props: {
      [modelProp]: {
        type: Object,
      },
    },
    model: {
      prop: modelProp,
      event: modelEvent,
    },
    data () {
      return {
        [alias]: new ctor(this[modelProp])
      }
    },
    watch: {
      [modelProp]: {
        deep: true,
        handler (payload) {
          const newValue = new ctor(payload)
          if (isEqual(this[alias], newValue)) return
          this[alias] = newValue
        }
      },
      [alias]: {
        deep: true,
        handler (payload) {
          this.$emit('input', payload)
        }
      }
    }
  }
}
