import api from '../utils/api'

function isObject (value) {
  if (typeof(value) !== 'object') return false
  if (Array.isArray(value)) return false
  if (value === null) return false
  return true
}

function detranslate (translations) {
  return Object
    .entries(translations)
    .reduce((acc, [locale, fields]) => {
      Object
        .entries(fields)
        .forEach(([fieldName, fieldValue]) => {
          if (!acc[fieldName]) {
            acc[fieldName] = {}
          }
          acc[fieldName][locale] = fieldValue
        })
      return acc
    }, {})
}

function detranslateRecursively (data) {
  return Object
    .entries(data)
    .reduce((acc, [key, value]) => {
      let result = value
      if (key === 'translations') {
        result = detranslate(value)
      } else if (isObject(value)) {
        result = detranslateRecursively(value)
      } else if (Array.isArray(value)) {
        result = value.map((value) => detranslateRecursively(value))
      }
      acc[key] = result
      return acc
    }, {})
}

function translateRecursively (data) {
  return Object
    .entries(data)
    .reduce((acc, [key, value]) => {
      let result = value
      if (key === 'translations') {
        result = translate(value)
      } else if (isObject(value)) {
        result = translateRecursively(value)
      }
      acc[key] = result
      return acc
    }, {})
}

function translate1 (entity) {
  const a = translateRecursively(entity)
  return a
}

function translate (data) {
  return Object
  .entries(data)
  .reduce((acc, [fieldName, locales]) => {
    Object.entries(locales).forEach(([locale, value]) => {
      if (!acc[locale]) {
        acc[locale] = {}
      }
      acc[locale][fieldName] = value
    })
    return acc
  }, {})
}

class BaseService {
  // must override
  get url () {
    return ''
  }

  get (id) {
    console.log('Base service: get', this.url)
    return api
      .get(`${this.url}/${id}`)
      .then((response) => this.detranslate(response))
  }

  getAll (params) {
    return api
      .get(`${this.url}`, { params: params })
      .then((response) => this.detranslate(response))
  }

  update (id, entity, config = { silent: false }) {
    const route =  [this.url, id].filter((item) => item).join('/')
    return api.patch(
      route,
      entity ? this.translate(entity) : entity,
      config
    )
  }

  create (entity, config = { silent: false }) {
    return api.post(
      `${this.url}`,
      entity ? this.translate(entity) : entity,
      config
    )
  }

  remove (id, config = { silent: false }) {
    return api.delete(`${this.url}/${id}`, config)
  }

  clearCache () {
    return api.get(`_cache/clear`)
  }

  clearCacheBooking () {
    return api.get(`_cache/booking`)
  }

  translate (entity) {
    if (!entity.translations) return entity
    return {
      ...entity,
      translations: translate(entity.translations)
    }
  }

  detranslate (response) {
    if (!response.translations) return response
    return {
      ...response,
      translations: detranslate(response.translations)
    }
  }
}

export default BaseService

