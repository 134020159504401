<script>
export default {
  render (h) {
    if (this.slim) {
      return this.$scopedSlots.default(this.scopedData)
    } else {
      return h(this.tag, {
        class: 'items-order',
        attrs: this.$attrs,
        // on: this.$listeners,
        props: this.$props,
      }, this.$scopedSlots.default(this.scopedData))
    }
  },
  model: {
    prop: 'items',
    event: 'input'
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    tag: {
      type: String,
      default: 'div'
    },
    slim: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    scopedData () {
      return {
        prev: (idx) => this.prev(idx),
        next: (idx) => this.next(idx),
      }
    },
  },
  methods: {
    prev (idx) {
      if (idx === 0) return
      let items = this.items.slice()
      const prevIdx = idx - 1
      const prev = items[prevIdx]
      const current = items[idx]
      items.splice(prevIdx, 2, current, prev)
      this.$emit('input', items)
    },
    next (idx) {
      if (idx >= this.items.length - 1) return
      let items = this.items.slice()
      items.splice(idx, 2, items[idx + 1], items[idx])
      this.$emit('input', items)
    }
  }
}
</script>
