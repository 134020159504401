<template>
  <div
    class="items-list-constructor"
  >
    <slot v-bind="scopedSlot"></slot>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'items',
    event: 'input'
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    scopedSlot () {
      return {
        add: (data) => this.add(data),
        remove: (idx) => this.remove(idx)
      }
    }
  },
  methods: {
    add (element) {
      const items = [ ...this.items, element ]
      this.input(items)
    },
    remove (idx) {
      const items = [ ...this.items ]
      items.splice(idx, 1)
      this.input(items)
    },
    input (value) {
      this.$emit('input', value)
    }
  },
}
</script>
