const isMainTenant = (tenantName) => /chaika/.test(tenantName)

export default class AppConfig {
  constructor ({
    endpoints,
    locales,
    tenant
  }) {
    Object.assign(this, {
      endpoints,
      locales,
      tenant: {
        ...tenant,
        isMainTenant: isMainTenant(tenant.name)
      }
    })
  }
}
