export const createRequest = (cb, defaultValue = null) => {
  return {
    pending: false,
    error: null,
    data: defaultValue,
    async run (...args) {
      let data
      let error
      this.pending = true
      try {
        data = await cb(...args)
        this.data = data
      } catch (e) {
        error = e
      }
      this.pending = false
      this.error = error
      if (error) throw error
      return data
    }
  }
}
