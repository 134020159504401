<template>
  <price-basic
    v-bind="{ ...$attrs, value: val, currency: cur }"
    v-on="$listeners"
  />
</template>

<script>
import PriceBasic from './Price/PriceBasic.vue'
import { CUR_AED, CUR_GEL, CUR_RUB, CUR_USD, CUR_AMD } from '@/consts/currency'

const currencySymbols = {
  [CUR_RUB]: '₽',
  [CUR_GEL]: '₾',
  [CUR_USD]: '$',
  [CUR_AED]: 'AED',
  [CUR_AMD]: '֏',
}

export default {
  name: 'VPrice',
  components: {
    PriceBasic
  },
  props: {
    currency: {
      type: String
    },
    value: {
      type: Number
    }
  },
  computed: {
    val () {
      return this.value / 100
    },
    cur () {
      return currencySymbols[this.currency] || this.currency
    }
  }
}
</script>
