import calc from './calc/index.json'
import buttons from './buttons'
import notes from './notes.json'
import tenant from './tenant.json'
import form from './form.json'
import clinic from './clinic.json'
import contact from './contact.json'

const domains = {
  calc,
  buttons,
  notes,
  tenant,
  form,
  clinic,
  contact
}

export default Object.entries(domains).reduce((acc, [name, locales]) => {
  Object.entries(locales).forEach(([locale, messages]) => {
    acc[locale] = acc[locale] ? acc[locale] : {}
    acc[locale][name] = messages
  })
  return acc
}, {})
