var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"mb-3",attrs:{"sm12":""}},[(_vm.title)?_c('page-header',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"right",fn:function(){return [(_vm.allowedCreate)?_c('v-btn',{attrs:{"right":"","small":"","color":"primary","to":_vm.createRoute}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1):_vm._e(),_vm._t("right-after")]},proxy:true}],null,true)}):_vm._e()],1),_vm._t("before-table"),_c('v-flex',[_vm._t("pretable"),_c('v-card',[(_vm.allowedSearch || !!_vm.$slots.filters)?_c('v-card-title',[(_vm.allowedSearch)?_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.searchPlaceholder,"single-line":"","hide-details":""},on:{"input":_vm.searchDebounce},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_vm._t("filters")],2):_vm._e(),_c(_vm.tableComponent,{tag:"component",attrs:{"hide-default-footer":_vm.disablePagination,"headers":_vm.headers,"items":_vm.items,"disable-sort":true,"disable-pagination":_vm.disablePagination,"page":_vm.pagination.current,"server-items-length":_vm.disablePagination ? _vm.items.length : _vm.pagination.total,"loading":_vm.loading,"draggable":_vm.draggable,"footer-props":{
                  itemsPerPageOptions: _vm.disablePagination ? null : [20, 40, 60],
                  showFirstLastPage: true,
                  disablePagination: _vm.disablePagination || _vm.loading,
                  disableItemsPerPage: _vm.loading
                },"dense":""},on:{"change":_vm.onTableChange,"update:page":_vm.updatePage,"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}}),{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"display":"inline-flex","grid-gap":"12px"}},[(_vm.viewRoute)?_c('v-btn',{attrs:{"small":"","icon":"","to":{ name: _vm.viewRoute, params: { id: _vm.get(item, _vm.idKey) } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-eye")])],1):_vm._e(),(_vm.updateRoute)?_c('v-btn',{attrs:{"small":"","icon":"","to":_vm.getUpdateUrl(item)}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1):_vm._e(),(_vm.allowedRemove)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.removeItemConfirm(item)}}},[_vm._v(" delete ")]):_vm._e(),(_vm.allowedSelect)?_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.$emit('select', item)}}},[_vm._v(" Выбрать ")]):_vm._e()],1)]}}],null,true)},[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)],1)],2),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.removeItemDialog),callback:function ($$v) {_vm.removeItemDialog=$$v},expression:"removeItemDialog"}},[_c('v-card',[(_vm.removeTitle)?_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.removeTitle)+" ")]):_vm._e(),(_vm.removeItem)?_c('v-card-text',[_vm._v(" "+_vm._s(_vm.removeMessageFormatted)+" ")]):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.removeItemCancel(_vm.removeItem)}}},[_vm._v(" Нет ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":"","loading":_vm.removing},on:{"click":function($event){return _vm.removeItemAccept(_vm.removeItem)}}},[_vm._v(" Да ")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }