<template>
  <div class="locale-text" v-if="items.length">
    <div v-for="item in items" :key="item.name" class="locale-text__item">
      <div class="locale-text__key" v-if="showKeys && !onlyLocale">
        <span class="locale-text__key-name">
          {{ item.name }}:
        </span>
      </div>
      <div class="locale-text__value">
        <span v-if="escape" v-html="item.value" />
        <span v-else>{{ item.value }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onlyLocale: {
      type: String
    },
    value: {
      type: Object,
      default: () => ({})
    },
    showKeys: {
      type: Boolean,
      default: true
    },
    escape: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    items () {
      return Object.entries(this.value).reduce((acc, [name, value]) => {
        const valid = this.onlyLocale ? this.onlyLocale === name : true
        if (valid) {
          acc.push({ name, value })
        }
        return acc
      }, [])
    }
  }
}
</script>

<style lang="scss" scoped>
.locale-text__item {
  display: flex;
  grid-gap: 0.25em;
}
.locale-text__key {
  font-family: monospace;
  line-height: 1em;
  display: flex;
  align-items: center;
}
.locale-text__key-name {
  font-size: 0.75em;
}
</style>
