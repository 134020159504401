<template>
  <div class="image-requirements">
    Оптимальные параметры: <span v-html="text"></span>
  </div>
</template>

<script>
export default {
  name: 'image-requirements',
  props: {
    ratio: {
      type: String
    },
    size: {
      type: String
    },
    ext: {
      type: String
    }
  },
  computed: {
    text () {
      return [
        this.ratio ? `<span style="white-space: nowrap">w/h = ${this.ratio}</span>` : null,
        this.size ? `<span style="white-space: nowrap">размер: ${this.size}</span>` : null,
        this.ext ? `<span style="white-space: nowrap">формат: ${this.ext}</span>` : null
      ].filter((item) => item).join(', ')
    }
  }
}
</script>
