<template>
  <vue-clip
    class="uploader"
    v-bind="attrs"
    v-on="$listeners"
  >
    <template
      slot="clip-uploader-body"
      scope="props"
    >
      <div class="uploader-files">
        <div class="uploader-file" v-for="file in props.files">
          <div class="file-avatar" v-if="file.dataUrl">
            <img v-bind:src="file.dataUrl"/>
          </div>
          <div class="file-details">
            <div class="file-name">{{ file.name }}</div>
            <div class="file-progress" v-if="file.status !== 'error' && file.status !== 'success'">
              <progress class="progress is-primary" :value="file.progress" max="100"></progress>
            </div>
            <div class="file-meta" v-else>
              <span class="file-size">{{ file.size }}</span>
              <span class="file-status">{{ file.status }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template slot="clip-uploader-action" scope="props">
      <slot name="action" v-bind="props">
        <div class="uploader-action" v-bind:class="{ dragging: props.dragging }">
          <div class="dz-message has-text-centered">
            {{ label }}
          </div>
        </div>
      </slot>
    </template>
  </vue-clip>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Добавить файл'
    }
  },
  computed: {
    attrs () {
      return {
        ...this.$attrs,
        onComplete: (...args) => this.onComplete(...args)
      }
    },
  },
  methods: {
    onComplete (file, status, xhr) {
      const resp = JSON.parse(xhr.responseText)

      switch (status) {
        case 'success':
          this.$emit('success', resp)
          break
        case 'error':
          this.$notify({
            title: `Ошибка (${resp.code || xhr.status})`,
            message: resp.message || '',
            type: 'error',
            duration: 3000
          })
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .uploader {
    display: flex;
    flex-direction: column-reverse;
  }

  .uploader * {
    box-sizing: border-box;
  }

  .uploader-action {
    margin:  20px 0;
    background: #F5F5F5FF;
    transition: background 300ms easy;

    .dz-message {
      cursor: pointer;
      padding: 20px 40px;
      border: 2px dashed #00D1B2FF;
      border-radius: 4px;
    }

    &.dragging {
      background: #fff;
    }
  }

  .uploader-files {
    flex: 1;
  }

  .uploader-file {
    display: flex;

    .file-avatar {
        margin-right: 20px;
    }

    .file-details {
      width: 100%;

      .file-status {
        text-transform: uppercase;
        margin-left: 1rem;
        font-weight: 600;
      }
      .file-progress {
        margin: 5px 0;
      }
    }
  }
</style>
