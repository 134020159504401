import Pagination from './pagination'
import Spinner from './spinner/Spinner.vue'
import CurrencyInput from './form/CurrencyInput.vue'
import Quill from './quilljs/Quill.vue'
import CalendarViewHeader from './calendar/CalendarViewHeader.vue'
import VMultiselect from "./VMultiselect"

const UIElements = {
  pagination: Pagination,
  spinner: Spinner,
  'currency-input': CurrencyInput,
  'quill-editor': Quill,
  'calendar-view-header': CalendarViewHeader,
  'v-multiselect': VMultiselect
}

export default UIElements
