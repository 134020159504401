import * as types from '../../mutation-types'
import lazyLoading from './lazyLoading'

// show: meta.label -> name
// name: component name
// meta.label: display label
const state = {
  items: [
    {
      name: 'Index',
      path: '/',
      meta: {
        icon: 'fa-tachometer',
        label: 'Dashboard'
      },
      component: lazyLoading('Widgets', false)
    },
    {
      name: 'MainPageSettings',
      path: '/mainpage',
      redirect: { name: 'Banner' },
      meta: {
        icon: 'window',
        label: "Главная страница",
        inSideBar: true,
        can: 'show MainPage',
      },
      component: lazyLoading('MainPage', false),
      children: [
        {
          name: 'PinnedLinks',
          path: 'pinned-links',
          meta: {
            label: 'Pinned links',
            inSideBar: false,
          },
          component: lazyLoading('PinnedLinks', false)
        },
        {
          name: 'Banner',
          path: 'banner',
          meta: {
            label: 'Баннер',
            inSideBar: false,
          },
          component: lazyLoading('BannerSlides', false)
        },
        {
          name: 'Other',
          path: 'other',
          meta: {
            label: 'Разное',
            inSideBar: false
          },
          component: lazyLoading('Other', false)
        }
      ]
    },
    {
      name: 'Config',
      path: '/config',
      meta: {
        icon: 'fa-gear',
        label: 'Настройки',
        can: 'show Config',
      },
      component: lazyLoading('config/Config', false)
    },
    {
      name: 'Documents',
      path: '/documents',
      meta: {
        icon: 'fa-file-text',
        label: 'Документы',
        // can: 'show Documents',
      },
      component: lazyLoading('documents/Documents', false)
    },
    {
      name: 'Document',
      path: '/documents/:id',
      meta: {
        label: 'Документ',
        inSideBar: false,
        // can: 'show Document',
        fail: '/documents'
      },
      component: lazyLoading('documents/Document', false)
    },
    {
      name: 'Official',
      path: 'official',
      meta: {
        icon: 'fa-file',
        label: 'Публичная информация',
        can: 'show Official'
      },
      component: lazyLoading('Official', false)
    },
    // {
    //   name: 'Specialities',
    //   path: '/specialities',
    //   meta: {
    //     label: 'Специальности',
    //     can: 'show Speciality',
    //     fail: '/'
    //   },
    //   component: lazyLoading('specialities/Specialities', false)
    // },
    // {
    //   name: 'Speciality',
    //   path: '/specialities/:id',
    //   meta: {
    //     label: 'Специальность',
    //     inSideBar: false,
    //     can: 'show Speciality',
    //     fail: '/specialities'
    //   },
    //   component: lazyLoading('specialities/Speciality', false)
    // },
    {
      name: 'Doctors',
      path: '/doctors',
      meta: {
        label: 'Врачи',
        can: 'show Doctor',
        fail: '/',
        icon: 'fa-user',
      },
      component: lazyLoading('doctors/Doctors', false)
    },
    {
      name: 'Doctor',
      path: '/doctors/:id',
      meta: {
        label: 'Доктор',
        inSideBar: false,
        can: 'show Doctor',
        fail: '/doctors',
      },
      component: lazyLoading('doctors/Doctor', false)
    },
    {
      name: 'PracticesIndex',
      path: '/practices',
      redirect: { name: 'PracticesList' },
      meta: {
        label: 'Практики',
        icon: 'fa-list-alt',
        can: 'show Practice'
      },
      component: lazyLoading('practice/Index', false),
      children: [
        {
          name: 'PracticesList',
          path: '/practices',
          meta: {
            label: 'Практики',
            inSideBar: false
          },
          component: lazyLoading('practice/List', false),
        },
        {
          name: 'PracticesSettings',
          path: '/practices/settings',
          meta: {
            inSideBar: false,
            fail: { name: 'PracticesList' },
          },
          component: lazyLoading('practice/Settings', false)
        },

      ]
    },
    {
      name: 'PracticeItem',
      path: '/practices/:id',
      meta: {
        inSideBar: false,
        fail: { name: 'PracticesList' },
      },
      component: lazyLoading('practice/Item', false)
    },
    {
      name: 'Clinics',
      path: '/clinics',
      meta: {
        label: 'Клиника',
        can: 'show Clinic',
        icon: 'fa-building',
        fail: '/'
      },
      component: lazyLoading('clinics/Clinics', false)
    },
    {
      name: 'Clinic',
      path: '/clinics/:id',
      meta: {
        label: 'Клиника',
        inSideBar: false,
        can: 'show Clinic',
        fail: '/clinics'
      },
      component: lazyLoading('clinics/Clinic', false)
    },
    {
      name: 'Services',
      path: '/services',
      meta: {
        label: 'Услуги',
        exact: true,
        can: 'show Service',
        fail: '/',
        icon: 'fa-list-ul'
      },
      component: lazyLoading('services/Services', false)
    },
    {
      name: 'Service',
      path: '/services/:id',
      meta: {
        label: 'Услуга',
        inSideBar: false,
        can: 'show Service',
        fail: '/services'
      },
      component: lazyLoading('services/Service', false)
    },
    {
      name: 'Users',
      path: '/users',
      meta: {
        icon: 'fa-male',
        label: 'Пользователи',
        expanded: false,
        exact: false,
        can: 'show User'
      },
      component: lazyLoading('users/Users', false)
    },
    {
      name: 'User',
      path: '/users/:id',
      meta: {
        label: 'Пользователь',
        inSideBar: false,
        can: 'show User',
        fail: '/users'
      },
      component: lazyLoading('users/User', false)
    },
    // {
    //   name: 'ServiceCategory',
    //   path: '/service-categories/:id',
    //   meta: {
    //     label: 'Категория услуги',
    //     inSideBar: false,
    //     can: 'show Service',
    //     fail: '/services'
    //   },
    //   component: lazyLoading('services/Category', false)
    // },
    // {
    //   name: 'Reports',
    //   meta: {
    //     label: 'Реестры',
    //     icon: 'fa-line-chart',
    //     expanded: false
    //   },
    //   children: [
    //     {
    //       name: 'Appointments',
    //       path: '/appointments',
    //       meta: {
    //         label: 'Записи на прием',
    //         exact: true,
    //         can: 'show Appointment'
    //       },
    //       component: lazyLoading('appointments/Appointments', false)
    //     },
    //     {
    //       name: 'MultiAppointments',
    //       path: '/multi-appointments',
    //       meta: {
    //         label: 'Записи на программы',
    //         exact: true,
    //         can: 'show Appointment'
    //       },
    //       component: lazyLoading('appointments/MultiAppointments', false)
    //     },
    //     {
    //       name: 'Payments',
    //       path: '/payments',
    //       meta: {
    //         label: 'Покупки',
    //         exact: true,
    //         can: 'show Payment'
    //       },
    //       component: lazyLoading('payments/Payments', false)
    //     },
    //     {
    //       name: 'Search',
    //       path: '/searchterm',
    //       meta: {
    //         label: 'Статистика поиска',
    //         exact: true,
    //         can: 'show SearchTerm'
    //       },
    //       component: lazyLoading('search/Search', false)
    //     },
    //     {
    //       name: 'MedicalCardHistory',
    //       path: '/mchistories',
    //       meta: {
    //         label: 'Статистика поиска ЛК',
    //         exact: true,
    //         can: 'show MedicalCardHistory'
    //       },
    //       component: lazyLoading('mchistory/MedicalCardHistory', false)
    //     },
    //     {
    //       name: 'UserFileHistory',
    //       path: '/user-file-histories',
    //       meta: {
    //         label: 'Статистика загрузок',
    //         exact: true,
    //         can: 'show UserFileHistory'
    //       },
    //       component: lazyLoading('user-file-history/History', false)
    //     }
    //   ]
    // },
    {
      name: 'BlogPosts',
      path: '/blog/posts',
      meta: {
        label: 'Блог',
        icon: 'fa-newspaper-o',
        inSideBar: true,
        can: 'show Post',
        fail: '/'
      },
      component: lazyLoading('blog/Posts', false)
    },
    {
      name: 'BlogPost',
      path: '/blog/posts/:id',
      meta: {
        label: 'Статья',
        inSideBar: false,
        can: 'show Post',
        fail: '/blog/posts'
      },
      component: lazyLoading('blog/Post', false)
    },
    // {
    //   name: 'RequestForms',
    //   path: '/request-forms',
    //   meta: {
    //     onlyMainTenant: true,
    //     icon: 'fa-tachometer',
    //     label: 'Формы-заявки',
    //     can: 'show RequestForm',
    //   },
    //   component: lazyLoading('request-forms/RequestForms', false)
    // },
    // {
    //   name: 'RequestForm',
    //   path: '/request-forms/:id',
    //   meta: {
    //     onlyMainTenant: true,
    //     inSideBar: false,
    //     can: 'show RequestForm',
    //   },
    //   component: lazyLoading('request-forms/RequestForm', false)
    // },
    {
      name: 'Photos',
      path: '/photos',
      meta: {
        label: 'Фотопоток',
        icon: 'fa-file-photo-o',
        inSideBar: true,
        can: 'show Photo',
        fail: '/'
      },
      component: lazyLoading('files/Photos', false)
    },
    {
      name: 'Photo',
      path: '/photos/:id',
      meta: {
        label: 'Изображение',
        inSideBar: false,
        can: 'show Photo',
        fail: '/photos'
      },
      component: lazyLoading('files/Photo', false)
    },
    {
      name: 'Files',
      path: '/files',
      meta: {
        label: 'Файлы',
        icon: 'fa-file',
        inSideBar: true,
        can: 'show Files',
        fail: '/'
      },
      component: lazyLoading('files/Files', false)
    },
    {
      name: 'File',
      path: '/files/:id',
      meta: {
        label: 'Файл',
        inSideBar: false,
        can: 'show File',
        fail: '/'
      },
      component: lazyLoading('files/File', false)
    },

    // {
    //   name: 'ScheduleOfDoctor',
    //   path: '/schedule-of-doctors/:id',
    //   meta: {
    //     label: 'Расписание врача',
    //     inSideBar: false
    //   },
    //   component: lazyLoading('appointments/ScheduleOfDoctor', false)
    // },
    // {
    //   name: 'HouseCallMap',
    //   path: '/house-call-maps/:id',
    //   meta: {
    //     onlyMainTenant: true,
    //     label: 'Вызов на дом',
    //     inSideBar: false,
    //     can: 'update HouseCallMap',
    //     fail: '/house-call-maps'
    //   },
    //   component: lazyLoading('appointments/HouseCallMap', false)
    // },
    // {
    //   name: 'WhatDoWeTreat',
    //   meta: {
    //     onlyMainTenant: true,
    //     label: 'Что мы лечим',
    //     icon: 'fa-heartbeat',
    //     expanded: false,
    //     can: 'show WhatDoWeTreat'
    //   },
    //   children: [
    //     {
    //       name: 'Symptoms',
    //       path: '/symptoms',
    //       meta: {
    //         onlyMainTenant: true,
    //         label: 'Симптомы',
    //         fail: '/symptoms'
    //       },
    //       component: lazyLoading('what-do-we-treat/Symptoms', false)
    //     },
    //     {
    //       name: 'Diseases',
    //       path: '/diseases',
    //       meta: {
    //         onlyMainTenant: true,
    //         label: 'Болезни',
    //         fail: '/diseases'
    //       },
    //       component: lazyLoading('what-do-we-treat/Diseases', false)
    //     },
    //     {
    //       name: 'Procedures',
    //       path: '/procedures',
    //       meta: {
    //         onlyMainTenant: true,
    //         label: 'Процедуры',
    //         fail: '/procedures'
    //       },
    //       component: lazyLoading('what-do-we-treat/Procedures', false)
    //     }
    //   ]
    // },
    // {
    //   name: 'Collaborators',
    //   meta: {
    //     label: 'ЛК МедПрофи',
    //     icon: 'fa-heartbeat',
    //     expanded: false
    //   },
    //   children: [
    //     {
    //       name: 'CollabDoctors',
    //       path: '/collaborate/users',
    //       meta: {
    //         label: 'Врачи',
    //         can: 'show Collab',
    //         fail: '/collaborate'
    //       },
    //       component: lazyLoading('collaborate/Users', false)
    //     },
    //     {
    //       name: 'CollabOrganizations',
    //       path: '/collaborate/org',
    //       meta: {
    //         label: 'Организации',
    //         can: 'show Collab',
    //         fail: '/collaborate'
    //       },
    //       component: lazyLoading('collaborate/Org', false)
    //     },
    //     {
    //       name: 'CollabPayments',
    //       path: '/collaborate/payments',
    //       meta: {
    //         label: 'Выплаты',
    //         can: 'show Collab',
    //         fail: '/collaborate'
    //       },
    //       component: lazyLoading('collaborate/Payments', false)
    //     }
    //   ]
    // },
    // {
    //   name: 'CollabDoctor',
    //   path: '/collaborate/:id',
    //   meta: {
    //     label: 'Врач',
    //     inSideBar: false,
    //     can: 'show Collab',
    //     fail: '/collaborate'
    //   },
    //   component: lazyLoading('collaborate/User', false)
    // },
    // {
    //   name: 'Symptom',
    //   path: '/symptoms/:id',
    //   meta: {
    //     onlyMainTenant: true,
    //     label: 'Симптом',
    //     inSideBar: false,
    //     can: 'show WhatDoWeTreat',
    //     fail: '/symptoms'
    //   },
    //   component: lazyLoading('what-do-we-treat/Symptom', false)
    // },
    // {
    //   name: 'Disease',
    //   path: '/diseases/:id',
    //   meta: {
    //     onlyMainTenant: true,
    //     label: 'Болезнь',
    //     inSideBar: false,
    //     can: 'show WhatDoWeTreat',
    //     fail: '/diseases'
    //   },
    //   component: lazyLoading('what-do-we-treat/Disease', false)
    // },
    // {
    //   name: 'Procedure',
    //   path: '/procedures/:id',
    //   meta: {
    //     onlyMainTenant: true,
    //     label: 'Процедура',
    //     inSideBar: false,
    //     can: 'show WhatDoWeTreat',
    //     fail: '/procedures'
    //   },
    //   component: lazyLoading('what-do-we-treat/Procedure', false)
    // },
    // {
    //   name: 'RefundRequest',
    //   path: '/refunds/request',
    //   meta: {
    //     label: 'Запрос на возврат',
    //     icon: 'fa-money',
    //     inSideBar: true
    //   },
    //   component: lazyLoading('payments/RefundRequest', false)
    // },
    // {
    //   name: 'Cmes',
    //   path: '/cmes',
    //   meta: {
    //     label: 'CME',
    //     icon: 'fa-universal-access',
    //     inSideBar: true
    //   },
    //   component: lazyLoading('cme/Cme', false)
    // },
    // {
    //   name: 'Cme',
    //   path: '/cmes/:id',
    //   meta: {
    //     label: 'CME',
    //     icon: 'fa-universal-access',
    //     inSideBar: false
    //   },
    //   component: lazyLoading('cme/CmeItem', false)
    // },
    {
      name: 'Jobs',
      path: '/jobs',
      meta: {
        label: 'Вакансии',
        icon: 'fa-pencil-square-o',
        inSideBar: true,
        can: 'show Jobs',
        fail: '/'
      },
      component: lazyLoading('jobs/Jobs', false)
    },
    {
      name: 'Job',
      path: '/jobs/:id',
      meta: {
        label: 'Вакансия',
        inSideBar: false,
        fail: '/jobs'
      },
      component: lazyLoading('jobs/Job', false)
    },
    {
      name: 'Pages',
      path: '/pages',
      meta: {
        label: 'Страницы',
        icon: 'fa-file-powerpoint-o',
        inSideBar: true,
        can: 'show Pages',
        fail: '/'
      },
      component: lazyLoading('pages/Pages', false)
    },
    {
      name: 'Page',
      path: '/pages/:id',
      meta: {
        label: 'Страница',
        inSideBar: false,
        fail: '/pages'
      },
      component: lazyLoading('pages/Page', false)
    },
    {
      name: 'InformerNotificationsList',
      path: '/notifications',
      meta: {
        label: 'Перетяжки',
        icon: 'notification_important',
        can: 'show InformerNotifications',
        inSideBar: true
      },
      component: lazyLoading('informer/Notifications', false)
    },
    {
      name: 'InformerNotificationEdit',
      path: '/notifications/:id',
      meta: {
        label: 'Перетяжка',
        inSideBar: false,
        can: 'show InformerNotifications',
        fail: '/notifications'
      },
      component: lazyLoading('informer/Notification', false)
    },
    {
      name: 'Cache',
      path: '/cache',
      meta: {
        icon: 'mdi-broom',
        label: 'Кэш',
        inSideBar: true,
        can: 'show Cache'
      },
      component: lazyLoading('Cache', false)
    },
  ]
}

const mutations = {
  [types.EXPAND_MENU] (state, menuItem) {
    if (menuItem.index > -1) {
      if (state.items[menuItem.index] && state.items[menuItem.index].meta) {
        state.items[menuItem.index].meta.expanded = menuItem.expanded
      }
    } else if (menuItem.item && 'expanded' in menuItem.item.meta) {
      menuItem.item.meta.expanded = menuItem.expanded
    }
  }
}

export default {
  state,
  mutations
}
