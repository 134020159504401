<template>
  <div class="price-value">
    <div class="price-value__number" v-html="number" />
    <div
      class="price-value__currency"
        v-if="currency"
        v-html="currency"
      />
  </div>
</template>

<script>
const divider = '&thinsp;'
const format = (string) => (string.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${divider}`))

export default {
  props: {
    currency: {
      type: String
    },
    value: {
      type: Number
    },
  },
  computed: {
    number () {
      return format(String(this.value))
    }
  }
}
</script>

<style lang="scss" scoped>
.price-value {
  display: inline-flex;
  align-items: center;
  grid-gap: 0.25em;
}
</style>
