<template>
  <v-select
    v-bind="{
      ...$attrs,
      items,
      itemText: 'text',
      itemValue: 'id'
    }"
    v-on="$listeners"
  >
    <template v-slot:selection="{ item }">
      <item-content :item="item" />
    </template>
    <template v-slot:item="{ item }">
      <item-content :item="item" />
    </template>
  </v-select>
</template>

<script>
import pagesService from '@/services/pages'

const ItemContent = {
  props: {
    item: {
      type: Object
    }
  },
  render (h) {
    const { title, name, uri } = this.item
    const text = [
      title ? title : name,
      title ? `(${name})` : null
    ].filter((item) => item).join(' ')

    return h('div', {
      class: 'd-flex justify-space-between align-center',
      attrs: {
        style: 'flex-wrap: wrap; width: 100%;'
      }
    }, [
      h('div', text),
      h('div', { class: 'caption', attrs: { style: 'opacity: 0.7' } }, uri)
    ])
  }
}

export default {
  components: {
    ItemContent
  },
  data () {
    return {
      loading: false,
      pages: []
    }
  },
  async created () {
    let error
    this.loading = true
    try {
      const { items: pages } = await pagesService.getAll()
      this.pages = pages
    } catch (e) {
      error = e
    }
    this.loading = false
    if (error) throw error
  },
  computed: {
    items () {
      return this.pages.map((page) => ({
        ...page,
        text: page.title || page.name
      }))
    }
  }
}
</script>
