<template>
    <div>
        <page-header
          class="mb-2"
          sm12
          :title="title"
          :subtitle="subtitle"
          :sticky="stickyHeader"
        >
            <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
        </page-header>
        <v-flex>
            <v-card>
                <div class="text-center" v-if="loading">
                    <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </div>
                <slot v-else></slot>
            </v-card>
        </v-flex>
    </div>
</template>

<script>
import PageHeader from './PageHeader'

export default {
  components: {
    PageHeader
  },
  $_veeValidate: {
    validator: 'new'
  },
  props: {
    title: { type: String, required: true },
    subtitle: { type: String, required: false },
    loading: { type: Boolean, default: false },
    stickyHeader: { type: Boolean, default: false }
  }
}
</script>
