export const TOGGLE_DEVICE = 'TOGGLE_DEVICE'

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'

export const EXPAND_MENU = 'EXPAND_MENU'

export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_IS_AUTH = 'SET_CURRENT_USER'
export const SET_CONFIG = 'SET_CONFIG'
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING'
