import BaseService from './baseService'

export function mergeAttachedRequestForms (requestForms = []) {
  return requestForms.map((form) => {
    const { useParentChannelId, code } = form.requestForm
    return {
      ...form,
      code,
      useParentChannelId: useParentChannelId && !form.channelId,
      channelId: form.channelId
        ? form.channelId
        : useParentChannelId
          ? null
          : form.requestForm.channelId,
    }
  })
}

class RequestFormService extends BaseService {
  get url () {
    return 'request-forms'
  }

  createModel (options = {}) {
    return {
      code: options.code || null,
      title: options.title || null,
      useParentChannelId: options.useParentChannelId || false,
      channelId: options.channelId || null,
      comment: options.comment || null
    }
  }
}

export default new RequestFormService()

