export default {
  methods: {
    getWebsiteUrl (path) {
      const config = this.$store.state.app.config
      return [
        config.endpoints.websiteEndpoint.replace(/\/$/, ''),
        path.replace(/^\//, '')
      ].filter((item) => item).join('/')
    }
  }
}
