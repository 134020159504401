<template>
  <v-input :value="value" :error-messages="errorMessages">
    <div class="v-multiselect">
      <label v-if="placeholder" :for="'v-multiselect_'+id" class="v-label" :class="{
        'error--text': errorMessages.length > 0
      }">{{ placeholder }}</label>
      <multiselect
         :value="value"
         @input="$emit('input', $event)"
         @change="$emit('change', $event)"
         :id="'v-multiselect_'+id"
         @search-change="$emit('search-change', $event)"
         :loading="loading"
         :internal-search="internalSearch"
         :options="options"
         :allow-empty="allowEmpty"
         :show-labels="showLabels"
         :searchable="searchable"
         :taggable="taggable"
         :multiple="multiple"
         :placeholder="placeholder"
         :track-by="trackBy"
         :label="label"
      />
    </div>
  </v-input>
</template>

<script>
  let id = 0;

  export default {
    props: {
      value: {
        required: true
      },
      errorMessages: {
        type: Array,
        default: []
      },
      placeholder: {
        type: String
      },
      trackBy: {
        type: String,
        default: 'id'
      },
      label: {
        type: String
      },
      loading: {
        type: Boolean,
        default: false
      },
      internalSearch: {
        type: Boolean,
        default: true
      },
      options: {
        type: Array,
        default: []
      },
      allowEmpty: {
        type: Boolean,
        default: false
      },
      showLabels: {
        type: Boolean,
        default: true
      },
      searchable: {
        type: Boolean,
        default: true
      },
      taggable: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      }
    },
    beforeCreate() {
      id += 1;
    },
    data () {
      return {
        id: id.toString()
      }
    }
  }
</script>

<style>
  .v-multiselect {
    flex-direction: column;
    width: 100%;
  }
</style>
