var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('choose-modal',{attrs:{"visible":_vm.visible,"closable":false,"title":_vm.title}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autofocus":"","placeholder":"Название","error-messages":errors},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Группа Workplace","error-messages":errors},model:{value:(_vm.form.group),callback:function ($$v) {_vm.$set(_vm.form, "group", $$v)},expression:"form.group"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Метка","error-messages":errors},model:{value:(_vm.form.label),callback:function ($$v) {_vm.$set(_vm.form, "label", $$v)},expression:"form.label"}})]}}],null,true)}),_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{attrs:{"type":"submit","color":"success"}},[_vm._v(" Сохранить ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.close}},[_vm._v(" Закрыть ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }