import Quill from 'quill'

let Text = Quill.import('blots/text')

class EnglishQuotes extends Text {
  static handler () {
    let range = this.quill.getSelection(false)
    this.quill.insertText(range.index, EnglishQuotes.OPEN_QUOTE, Quill.sources.USER)
    this.quill.insertText(range.index + range.length + 1, EnglishQuotes.CLOSE_QUOTE, Quill.sources.USER)
    this.quill.setSelection(range.index + 1, range.length, Quill.sources.SILENT)
  }
}
EnglishQuotes.blotName = 'english-quotes'
EnglishQuotes.OPEN_QUOTE = '“'
EnglishQuotes.CLOSE_QUOTE = '”'

export default EnglishQuotes
