<template>
<div class="embed-constructor">
  <validation-provider
      rules="required"
      v-slot="v"
    >
    <v-text-field
      name="label"
      dense
      outlined
      v-model="form.label"
      placeholder="Метка"
      :error="!!v.failedRules.required"
      :hint="v.failedRules.required ? 'Необходимо заполнить' : ''"
      persistent-hint
    />
  </validation-provider>

  <items-constructor-provider
      v-model="form.items"
      v-slot="{ add, remove }"
    >
      <items-order-provider
        style="margin-bottom: 20px"
        v-model="form.items"
        v-slot="{ next, prev }"
      >
        <v-expansion-panels v-model="opened">
          <embed-booking-item-constructor
            v-model="item.booking"
            v-for="(item, idx) in form.items"
            :key="item.id"
            @prev="prev(idx)"
            @next="next(idx)"
            @remove="remove(idx)"
          />
        </v-expansion-panels>
      </items-order-provider>

      <v-btn @click="create(add)" color="success">
        Добавить услугу
      </v-btn>
    </items-constructor-provider>
  </div>
</template>

<script>
import EmbedBookingItemConstructor from './EmbedBookingItemConstructor.vue'
import ItemsOrderProvider from '../../ItemsOrderProvider.vue'
import ItemsConstructorProvider from '../../ItemsConstructorProvider.vue'
import { v4 as uuid } from 'uuid'
import { EmbedBooking } from '../../../models/EmbedBooking'

class EmbedBookingWrapper {
  constructor (options) {
    Object.assign(this, new EmbedBooking(options))
    this.items = this.items.map((item) => EmbedBookingWrapper.createItem(item))
  }

  static createItem (item) {
    return {
      id: uuid(),
      booking: item
    }
  }

  static serialize (embedBookingWrapper) {
    const items = embedBookingWrapper.items.map(({ booking }) => booking)
    return { ...embedBookingWrapper, items }
  }
}

export default {
  components: {
    ItemsOrderProvider,
    ItemsConstructorProvider,
    EmbedBookingItemConstructor
  },
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      form: new EmbedBookingWrapper(this.value || {}),
      opened: null
    }
  },
  watch: {
    form: {
      deep: true,
      handler (form) {
        this.$emit('input', EmbedBookingWrapper.serialize(form))
      }
    }
  },
  methods: {
    create (addListItem) {
      const item = EmbedBookingWrapper.createItem()
      addListItem(item)
      this.opened = this.form.items.length - 1
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
