import BaseService from './baseService'
// import api from '../utils/api'

class ConfigService extends BaseService {
  get url () {
    return 'config'
  }

  getEndpoints () {
    return this.get('endpoints')
  }
}

export default new ConfigService()

