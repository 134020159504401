<template>
  <choose-modal
    :closable="false"
    :visible="visible"
    @close="close"
    title="Запись на прием"
  >

    <validation-observer v-slot="{ failed, handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <embed-booking-constructor
          style="margin-top: 20px"
          v-model="form"
        />

        <div style="margin-top: 40px">
          <div v-if="failed" style="margin-bottom: 12px" class="red--text">
            Заполните обязательные поля
          </div>

          <v-row justify="space-between">
            <v-btn
              type="submit"
              color="success"
              :disabled="failed || !form || !form.items.length"
            >
              Сохранить
            </v-btn>
            <v-btn color="red" @click="close">Закрыть</v-btn>
          </v-row>
        </div>
      </form>
    </validation-observer>
  </choose-modal>
</template>

<script>
import ChooseModal from "../../components/elements/modals/ChooseModal"
import EmbedBookingConstructor from '../../components/Embed/Booking/EmbedBookingConstructor.vue'

export default {
  components: {
    ChooseModal,
    EmbedBookingConstructor
  },
  props: {
    visible: Boolean,
    title: String,
    booking: Object
  },
  data () {
    return {
      form: this.booking
    }
  },
  methods: {
    async submit () {
      this.$emit('choose', this.form)
      this.visible = false
    },
    close () {
      this.visible = false
    }
  }
}
</script>
