import Quill from 'quill'

let Text = Quill.import('blots/text')

class FrenchQuotes extends Text {
  static handler () {
    let range = this.quill.getSelection(false)
    this.quill.insertText(range.index, FrenchQuotes.OPEN_QUOTE, Quill.sources.USER)
    this.quill.insertText(range.index + range.length + 1, FrenchQuotes.CLOSE_QUOTE, Quill.sources.USER)
    this.quill.setSelection(range.index + 1, range.length, Quill.sources.SILENT)
  }
}

FrenchQuotes.blotName = 'french-quotes'
FrenchQuotes.OPEN_QUOTE = '«'
FrenchQuotes.CLOSE_QUOTE = '»'

export default FrenchQuotes
