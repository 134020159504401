var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('locale-field',{attrs:{"component":"v-text-field","rules":"required","name":"label","autofocus":"","label":"Название пункта меню","outlined":"","dense":"","hide-details":""},model:{value:(_vm.item.label),callback:function ($$v) {_vm.$set(_vm.item, "label", $$v)},expression:"item.label"}}),_c('v-checkbox',{staticClass:"mt-2",attrs:{"label":"Исключить из меню"},model:{value:(_vm.item.disabled),callback:function ($$v) {_vm.$set(_vm.item, "disabled", $$v)},expression:"item.disabled"}}),_c('validation-provider',{attrs:{"rules":"required","name":"type","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mt-4",attrs:{"dense":"","outlined":"","hide-details":"","label":"Тип","error-messages":errors,"name":"type","item-text":"text","item-value":"value","items":[
        { text: 'Список', value: 'list' },
        { text: 'Ссылка', value: 'url' },
        { text: 'Страница', value: 'page' } ]},on:{"input":_vm.onInputType},model:{value:(_vm.item.type),callback:function ($$v) {_vm.$set(_vm.item, "type", $$v)},expression:"item.type"}})]}}])}),(_vm.item.type === _vm.TYPE_URL)?_c('validation-provider',{attrs:{"slim":"","name":"url","rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"name":"url","label":"Адрес","placeholder":"https://example.com","outlined":"","dense":"","error-messages":errors,"hide-details":""},model:{value:(_vm.item.url),callback:function ($$v) {_vm.$set(_vm.item, "url", $$v)},expression:"item.url"}})]}}],null,false,1797097108)}):_vm._e(),(_vm.item.type === _vm.TYPE_PAGE)?_c('validation-provider',{attrs:{"slim":"","name":"page","rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('page-select',{staticClass:"mt-4",attrs:{"outlined":"","dense":"","hide-details":"","label":"Страница","error-messages":errors,"placeholder":"Выберите страницу"},model:{value:(_vm.item.pageId),callback:function ($$v) {_vm.$set(_vm.item, "pageId", $$v)},expression:"item.pageId"}})]}}],null,false,6079171)}):_vm._e(),(_vm.item.type === _vm.TYPE_LIST)?_c('menu-editor',{staticClass:"mt-4",model:{value:(_vm.item.items),callback:function ($$v) {_vm.$set(_vm.item, "items", $$v)},expression:"item.items"}}):_vm._e(),_c('v-text-field',{staticClass:"mt-12",attrs:{"outlined":"","dense":"","hide-details":"","label":"Иконка","placeholder":"Название иконки"},model:{value:(_vm.item.icon),callback:function ($$v) {_vm.$set(_vm.item, "icon", $$v)},expression:"item.icon"}}),_c('validation-provider',{attrs:{"name":"trackingName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"outlined":"","dense":"","hide-details":"","name":"trackingName","label":"Метка","error-messages":errors,"placeholder":"Метка для метрики"},model:{value:(_vm.item.trackingName),callback:function ($$v) {_vm.$set(_vm.item, "trackingName", $$v)},expression:"item.trackingName"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }