import Quill from 'quill'
import { encode, decode } from './BookingEmbed'

let BlockEmbed = Quill.import('blots/block/embed')

class Claim extends BlockEmbed {
  static create (value) {
    let node = super.create()
    const { group, name } = value
    node.innerHTML = `Оставить заявку - ${name} (${group} )`
    node.dataset.embed = encode('claim', value)
    return node
  }


  static value (domNode) {
    const json = domNode.dataset.embed
    return json ? decode(json) : null
  }
}

Claim.blotName = 'claim'
Claim.className = 'ql-claim-block'
Claim.tagName = 'div'

export default Claim
