import moment from 'moment'
import accounting from 'accounting'

export function date (value, format) {
  return moment(value).format(format || 'DD.MM.YYYY')
}

export function dateTime (value, format) {
  return moment(value).format(format || 'DD.MM.YYYY HH:mm:ss')
}

export function fullName (value) {
  return [value.lastName, value.firstName, value.patronymic].join(' ').trim()
}

export function currency (value, precision = 2, thousand = ',', decimal = '.') {
  return accounting.formatNumber(value, precision, decimal, thousand)
}

export function pretty (value) {
  return JSON.stringify(JSON.parse(value), null, 2)
}
