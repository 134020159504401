<template>
  <v-container fluid class="px-0 quill">
    <label class="v-label v-label--active theme--light" v-if="label">{{ label }}</label>
      <vue-editor
        :class="stickyToolbar ? 'quill-sticky-toolbar' : ''"
        class="d-block"
        :editorOptions="options"
        :value="value"
        @input="onChange"
      >
      </vue-editor>
  </v-container>
</template>

<script>
export default {
  props: {
    value: { required: true },
    label: { type: String, required: false },
    stickyToolbar: { type: Boolean, default: true },
    options: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    }
  },
  methods: {
    onChange (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
  .quill {
    color: black;
    margin-top: 5px;
  }

  .ql-clipboard {
    // Fix: scroll "jump" after pasting content
    // https://github.com/davidroyer/vue2-editor/issues/265
    position: fixed !important;
    display: none !important;
    left: 50% !important;
    top: 50% !important;
  }

  .ql-editor {
    min-height: 100px !important;
  }

  .quill {
    .ql-editor > blockquote {
      margin: 20px 0 !important;
    }

    .ql-editor > p {
      margin-bottom: 16px;
    }

    .ql-editor > ul,
    .ql-editor > ol {
      margin-bottom: 16px;
    }

    .ql-editor > .image {
      margin: 32px 0
    }

    .ql-editor > h2 {
      margin-top: 32px;
      margin-bottom: 8px;
    }

    .ql-editor > h3 {
      margin-top: 20px;
      margin-bottom: 4px;
    }

    .ql-editor > h4 {
      margin-top: 16;
      margin-bottom: 4px;
    }

    .ql-claim-block {
      background: #eee;
      padding: 20px;
      margin: 20px 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    .ql-preface {
      background: #eee;
      padding: 1em;
      margin-bottom: 1em;
    }
  }

  .quill-sticky-toolbar .ql-toolbar {
    position: sticky;
    background: #fff;
    top: 48px;
    z-index: 2;
  }
</style>
