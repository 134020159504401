import { LocaleField } from './LocaleField'

export class ItemMeta {
  constructor (options) {
    const { title } = options || {}
    return {
      title: new LocaleField(title)
    }
  }
}
