<template>
    <v-layout wrap>
        <v-flex sm12 class="mb-3">
            <page-header
                :title="title"
                v-if="title"
            >
                <template v-slot:right>
                    <v-btn
                       v-if="allowedCreate"
                       right small
                       color="primary"
                       :to="createRoute"
                    >
                        <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                    <slot name="right-after"></slot>
                </template>
            </page-header>
        </v-flex>
        <slot name="before-table"></slot>
        <v-flex>
            <slot name="pretable"></slot>
            <v-card>
                <v-card-title v-if="allowedSearch || !!$slots.filters">
                    <v-text-field
                        v-if="allowedSearch"
                        v-model="search"
                        append-icon="search"
                        :label="searchPlaceholder"
                        single-line
                        hide-details
                        @input="searchDebounce"
                    ></v-text-field>
                    <slot name="filters"></slot>
                </v-card-title>
                <component
                    :is="tableComponent"
                    :hide-default-footer="disablePagination"
                    :headers="headers"
                    :items="items"
                    :disable-sort="true"
                    :disable-pagination="disablePagination"
                    :page="pagination.current"
                    :server-items-length="disablePagination ? items.length : pagination.total"
                    :loading="loading"
                    :draggable="draggable"
                    @change="onTableChange"
                    @update:page="updatePage"
                    @update:items-per-page="updateItemsPerPage"
                    :footer-props="{
                      itemsPerPageOptions: disablePagination ? null : [20, 40, 60],
                      showFirstLastPage: true,
                      disablePagination: disablePagination || loading,
                      disableItemsPerPage: loading
                    }"
                    dense
                >
                    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
                    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                        <slot :name="name" v-bind="slotData" />
                    </template>

                    <!--TODO: правильно обрабатывать роуты -->
                    <template v-slot:item.action="{ item }">
                      <div style="display: inline-flex; grid-gap: 12px">
                        <v-btn small icon v-if="viewRoute"
                          :to="{ name: viewRoute, params: { id: get(item, idKey) } }"
                        >
                          <v-icon small>fa-eye</v-icon>
                        </v-btn>

                        <v-btn small icon v-if="updateRoute"
                          :to="getUpdateUrl(item)"
                        >
                            <v-icon small>edit</v-icon>
                        </v-btn>
                        <v-icon v-if="allowedRemove"
                            small
                            @click="removeItemConfirm(item)"
                        >
                            delete
                        </v-icon>
                        <v-btn
                          color="success"
                          v-if="allowedSelect"
                          @click="$emit('select', item)"
                        >
                          Выбрать
                        </v-btn>
                      </div>
                    </template>

                </component>
            </v-card>
        </v-flex>
        <v-dialog v-model="removeItemDialog" width="500">
            <v-card>
                <v-card-title class="headline" v-if="removeTitle">
                    {{ removeTitle }}
                </v-card-title>
                <v-card-text v-if="removeItem">
                    {{ removeMessageFormatted }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text
                       @click="removeItemCancel(removeItem)"
                    >
                        Нет
                    </v-btn>
                    <v-btn color="red darken-1" text
                       :loading="removing"
                       @click="removeItemAccept(removeItem)"
                    >
                        Да
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
import config from '../config'
import isObject from 'lodash/isObject'
import debounce from 'lodash/debounce'
import template from 'lodash/template'
import get from 'lodash/get'
import PageHeader from './PageHeader'
import DataTableDraggable from '@/components/DataTable'
import DataTableNormal from 'vuetify/es5/components/VDataTable/VDataTable'
export const ON_REORDER = 'reorder'

export default {
  components: {
    PageHeader
  },
  props: {
    api: { type: Object, required: true },
    title: { default: null },
    headers: { type: Array, required: true },
    itemsKey: { type: String, default: 'items' },
    idKey: { type: String, default: 'id' }, // rename to idField
    allowedSearch: { type: Boolean, default: false },
    allowedCreate: { type: Boolean, required: true },
    searchPlaceholder: { type: String, default: 'Поиск' },
    searchParameter: { type: String, default: 'search' },
    viewRoute: { type: Object, default: null },
    createRoute: { type: Object, default: null },
    updateRoute: { type: [String, Object, Function], default: null },
    allowedRemove: { type: Boolean, default: null },
    allowedSelect: { type: Boolean, default: false },
    removeTitle: { type: String, default: null },
    removeMessage: { type: String, default: `Вы действительно хотите удалить?` },
    customData: { type: Object, default: () => ({}) },
    requestParams: { type: Object, default: null },
    disablePagination: { type: Boolean, default: false },
    draggable: { type: Boolean, default: false }
  },
  data: () => ({
    items: [],
    loading: false,
    removing: false,
    removeItem: null,
    removeItemDialog: false,
    pagination: { current: 1, total: null, limit: config.paginationLimit },
    search: null,
    tableOptions: {},
    filter: { },
    removeMessageTpl: null
  }),
  computed: {
    tableComponent () {
      return this.draggable ? DataTableDraggable : DataTableNormal
    },
    removeMessageFormatted () {
      if (!this.removeItem) {
        return null
      }
      return this.removeMessageTpl({ item: this.removeItem })
    }
  },
  mounted () {
    this.removeMessageTpl = template(this.removeMessage)
    this.filter = { ...this.customData }
    this.fetch(false)
  },
  watch: {
    requestParams: {
      deep: true,
      handler () {
        this.pagination.current = 1
        this.fetch()
      }
    }
  },
  methods: {
    getUpdateUrl (item) {
      const { updateRoute } = this
      if (typeof updateRoute === 'string') {
        return { name: updateRoute, params: { id: get(item, this.idKey) } }
      } else if (typeof updateRoute === 'object') {
        return updateRoute
      } else if (typeof updateRoute === 'function') {
        return updateRoute(item)
      }
    },
    onTableChange (items) {
      if (this.draggable) {
        this.items = items
        this.$emit(ON_REORDER, items)
      }
    },
    searchDebounce: debounce(function (...args) {
      this.pagination.current = 1
      this.fetch(...args)
    }, 1000),
    updatePage (page) {
      if (this.loading) return
      this.pagination.current = page
      this.fetch()
    },
    updateItemsPerPage (limit) {
      if (this.loading) return
      this.pagination.current = 1
      this.pagination.limit = limit
      this.fetch()
    },
    fetch (updateUrl = true) {
      // if (this.loading) return

      // this.loading = true

      let query = { }

      if (this.allowedSearch && this.search) {
        query[this.searchParameter] = this.search.trim()
      }

      if (Object.keys(this.filter).length > 0) {
        query.filter = this.filter
      }

      if (!this.disablePagination) {
        query.page = this.pagination.current
        query.limit = this.pagination.limit
      }

      let request = Object.assign({ }, query)

      if (isObject(this.requestParams)) {
        request = Object.assign(
          request, this.requestParams
        )
      }

      this.api.getAll(request).then(
        response => {
          if (this.disablePagination) {
            this.items = response
          } else {
            this.items = response[this.itemsKey]
            this.pagination.total = response.total
            this.pagination.limit = response.limit
            if (updateUrl) {
              this.$router.push({ query: query })
            }
          }
          // this.loading = false
        },
        e => {
          console.log(e)
          // this.loading = false
          this.$notify.error(e.message)
        }
      )
    },
    removeItemConfirm (item) {
      this.removeItem = item
      this.removeItemDialog = true
    },
    removeItemAccept (item) {
      let clear = () => {
        this.removeItemDialog = false
        this.removeItem = null
        this.removing = false
      }

      this.removing = true
      try {
        this.api.remove(get(item, this.idKey)).then(response => {
          let idx = this.items.indexOf(item)
          this.items.splice(idx, 1)
          clear()
        }).catch(err => {
          console.log(err)
          clear()
        })
      } catch (e) {
        console.log(e)
        this.$notify.error(e.message)
        clear()
      }
    },
    removeItemCancel (item) {
      this.removeItemDialog = false
      this.removeItem = null
    },
    get (item, key) {
      return get(item, key)
    }
  }
}
</script>
