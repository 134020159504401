import Vue from 'vue'
import { Quill } from 'vue2-editor'
import BookingEmbed from '../formats/BookingEmbed'
import BookingModal from 'views/modals/BookingModal'
import vuetify from '@/plugins/vuetify'
import { EmbedBooking } from '../../../../models/EmbedBooking'
const BookingModalComponent = Vue.extend(BookingModal)

function showModal (booking) {
  return new BookingModalComponent({
    el: document.createElement('div'),
    vuetify: vuetify,
    propsData: {
      booking,
      visible: true,
    }
  })
}

export default class BookingModule {
  constructor (quill) {
    this.quill = quill
    this.toolbar = quill.getModule('toolbar')

    this.toolbar.addHandler('booking', (value) => {
      if (value) {
        this.create()
      } else {
        this.quill.format('booking', false)
      }
    })

    this.quill.root.addEventListener('click', (e) => {
      const blot = Quill.find(e.target)
      const isBooking = blot instanceof BookingEmbed
      if (!isBooking) {
        return
      } else {
        let { booking } = blot.value() || {}
        if (!booking.version) {
          const message = 'Booking non editable. Invlid format'
          console.error(message)
          alert(message)
        } else {
          this.edit(new EmbedBooking(booking))
        }
      }
    })
  }

  edit (booking) {
    const range = this.quill.getSelection()
    const index = range.index - 1
    showModal(booking).$once('choose', (data) => {
      this.quill.deleteText(index, 1)
      this.quill.insertEmbed(index, 'booking', data, Quill.sources.USER)
    })
  }

  create () {
    const range = this.quill.getSelection()
    showModal().$once('choose', (data) => {
      this.quill.setSelection(range)
      this.quill.insertEmbed(range.index, 'booking', data, Quill.sources.USER)
      // setTimeout(() => this.quill.setSelection(range.index + 1, 0), 0)
      // returnScroll(this.quill, range.index + 1, scrollTop)
    })
  }
}
