<template>
  <v-autocomplete
    :value="value"
    v-bind="attrs"
    v-on="listeners"
    dense
    :loading="loading"
    :search-input.sync="search"
  />
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  props: {
    value: {
      type: Object,
    },
    debounceValue: {
      type: Number,
      default: 500
    },
    load: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      items: [],
      loading: false,
      search: null,
      error: null,
    }
  },
  computed: {
    attrs () {
      return {
        ...this.$attrs,
        items: this.items,
        value: this.value,
      }
    },
    listeners () {
      return {
        ...this.$listeners,
        input: (e) => this.$emit('input', e)
      }
    }
  },
  mounted () {
    if (this.value) {
      this.$nextTick(() => {
        this.search = this.value.name
      })
    }
  },
  created () {
    this.fetch = debounce(this.fetch.bind(this), this.debounceValue)
  },
  watch: {
    search () {
      this.fetch()
    },
  },
  methods: {
    async fetch () {
      if (this.loading) return
      let data
      let error
      this.loading = true
      try {
        data = await this.load(this.search)
      } catch (e) {
        error = e
        console.error(e)
      }
      this.items = data
      this.loading = false
      this.error = error
      if (error) {
        this.$emit('error', error)
      }
    }
  }
}
</script>
