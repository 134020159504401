<template>
  <div>
    <locale-field
      component="v-text-field"
      name="title"
      placeholder="Title"
      v-model="form.title"
      :error="!!error"
      outlined
      dense
      hide-details
      label="Title"
    />
    <div v-if="error" class="mt-2 red--text">
      {{ error }}
    </div>
  </div>
</template>

<script>
import { ItemMeta } from '@/models'
import { createFormComponent } from '@/utils'
import { LocaleField } from '@/components/Locale'

export default {
  components: {
    LocaleField
  },
  props: {
    error: {
      type: String
    }
  },
  mixins: [
    createFormComponent({
      ctor: ItemMeta
    })
  ],
}
</script>
