import { locales } from '@/store/modules/app'

export class LocaleField {
  constructor (options) {
    return locales.reduce((acc, key) => {
      acc[key] = options ? options[key] : null
      return acc
    }, {})
  }
}
