<template>
  <transition
      :name="transition"
      mode="in-out"
      appear
      :appear-active-class="enterClass"
      :enter-active-class="enterClass"
      :leave-active-class="leaveClass"
      @beforeEnter="beforeEnter"
      @afterEnter="afterEnter"
      @beforeLeave="beforeLeave"
      @afterLeave="afterLeave"
  >
    <v-dialog
      v-model="show"
      scrollable
      max-width="800"
    >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            {{ title }}
          </v-card-title>

          <v-card-text>
            <slot></slot>
          </v-card-text>

          <v-card-actions class="justify-end" v-if="closable">
            <v-btn
              text
              @click="show = false"
            >Закрыть</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </transition>
</template>

<script>
import BaseModal from './BaseModal'

export default {
  mixins: [BaseModal],

  props: {
    title: {
      type: String
    },
    closable: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
