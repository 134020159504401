import BaseService from './baseService'
import omit from "lodash/omit";
import get from "lodash/get";
import api from '../utils/api'
import { mergeAttachedRequestForms } from './request-form';
import { mapGalleryPhotos } from '../mappers/GalleryPhotosMapper';

class DoctorService extends BaseService {
  get url () {
    return 'doctors'
  }

  async get (doctorId) {
    const doctor = await api.get(`${this.url}/${doctorId}`)
    return {
      ...doctor,
      requestForms: mergeAttachedRequestForms(doctor.requestForms)
    }
  }

  toDto (entity) {
    let dto = omit(entity, ['examplesOfWorks'])
    dto.examplesOfWorks = []

    for (const i in entity.examplesOfWorks) {
      let e = omit(entity.examplesOfWorks[i], ['after', 'before'])
      e.before = get(entity.examplesOfWorks[i], 'before.id', null)
      e.after = get(entity.examplesOfWorks[i], 'after.id', null)
      dto.examplesOfWorks.push(e)
    }

    dto.galleryPhotos = mapGalleryPhotos(entity.galleryPhotos)
    dto.orderOfSpecialities = entity.specialities.map(({ id }) => id)

    dto.requestForms = entity.requestForms.map((form) => ({
      code: form.code,
      title: form.title,
      channelId: form.channelId,
      comment: form.comment
    }))

    return dto
  }
}

export default new DoctorService()

