<template>
  <choose-modal :visible="visible" @close="close" :title="title">

    <item-list-view
      ref="list"
      :api="api"
      :headers="headers"
      id-key="id"
      :allowed-search="true"
      :allowed-create="false"
      :allowed-remove="false"
    >
      <template v-slot:before-table>
        <v-flex style="width: 100%">
          <ImagesUploader @success="fetchDebounce" />
        </v-flex>
      </template>

      <template v-slot:item.image="{ item }">
        <div style="max-width: 150px;">
          <img
            :src="item.thumbnailUrl"
            style="max-width: 100%"
          />
        </div>
      </template>
      <template v-slot:item.info="{ item }">
        <strong v-if="item.title">{{ item.title }}<br/></strong>
        {{ item.createdAt | dateTime }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn @click="choose(item)">выбрать</v-btn>
      </template>
    </item-list-view>

  </choose-modal>
</template>

<script>
import ChooseModal from "../../components/elements/modals/ChooseModal"
import api from "../../services/photos"
import ItemListView from "../../components/ItemListView"
import debounce from 'lodash/debounce'
import ImagesUploader from '../../components/elements/upload/ImagesUploader.vue'

export default {
  components: {
    ItemListView,
    ChooseModal,
    ImagesUploader
  },
  data: () => ({
    api: api,
    headers: [
      { text: 'Изображение', value: 'image' },
      { text: 'Инфо', value: 'info' },
      { text: '', value: 'actions' }
    ],
  }),
  props: {
    visible: Boolean,
    title: String
  },
  methods: {
    fetchDebounce: debounce(function() {
      this.$refs.list.searchDebounce(false)
    }, 500),
    close () {
      this.$emit('close')
      // this.$destroy()
    },
    choose (image) {
      this.$emit('choose', image)
      this.visible = false
      // this.$destroy()
    }
  }
}
</script>
