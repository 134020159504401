import Quill from 'quill'
const Embed = Quill.import('blots/block/embed')
const ATTR_NAME = 'data-embed'
const EMBED_NAME = 'booking'

function style (node) {
  node.style.padding = '8px'
  node.style.marginTop = '20px'
  node.style.marginBottom = '20px'
  node.style.backgroundColor = '#eee'
  return node
}

function makeText (embedData) {
  const { items, label } = embedData
  const details = items.reduce((acc, data) => {
    const { doctor, clinic, service } = data || {}
    const details = [
      doctor?.fullName ? `Доктор: ${doctor.fullName}` : null,
      clinic?.name ? `Клиника: ${clinic.name}` : null,
      service?.name ? `Услуга: ${service.name}` : null,
      label ? `Метка: ${label}` : null
    ]
      .filter((item) => item)
      .join('<br/>')

    acc.push(`@booking<br/>${details}`)
    return acc
  }, [])
  return details.join('<br />')
}

export function encode (name, data) {
  return encodeURIComponent(JSON.stringify({ name, data }))
}

export function decode (string) {
  const object = JSON.parse(decodeURIComponent(string))
  return object.data
}

class BookingEmbed extends Embed {
  static create (data) {
    const node = super.create()
    node.setAttribute(ATTR_NAME, encode(EMBED_NAME, data))
    node.setAttribute('contenteditable', false)
    node.innerHTML = makeText(data)
    return style(node)
  }

  static value (domNode) {
    const json = domNode.getAttribute(ATTR_NAME)
    return json ? decode(json) : null
  }

}

BookingEmbed.blotName = 'booking'
BookingEmbed.tagName = 'div'

export default BookingEmbed
