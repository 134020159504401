import Bootstrap from './bootstrap'
import vuetify from './plugins/vuetify'
import VueMask from 'v-mask'
import isUndefined from 'lodash/isUndefined'
import Vue from 'vue'
import NProgress from 'vue-nprogress'
import { sync } from 'vuex-router-sync'
import Acl from 'vue-browser-acl'
import AclInstance from './acl'
import App from './App.vue'
import router from './router'
import store from './store'
import config from './config'
import { SET_CURRENT_USER } from './store/mutation-types'
import { Notification } from 'element-ui'
import has from 'lodash/has'
import locales from './locales'
import VueI18n from 'vue-i18n'

Bootstrap.i18n()
Bootstrap.validator()
Bootstrap.components()
Bootstrap.plugins({ router })
Bootstrap.filters()
Bootstrap.mixins()

Vue.config.productionTip = false

Vue.http.options.root = config.prefixUrlApi
Vue.http.options.credentials = true

sync(store, router)

let userFetched = false

router.beforeEach(async (to, from, next) => {
  if (!userFetched) {
    await store.dispatch('whoami')
    userFetched = true;
  }
  next()
})

router.beforeEach(async (to, from, next) => {
  if (to.name !== 'Login' && to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath, autologin: 1 }
      })
      return
    } else {
      await loadConfig()
      checkIsRouteAllowedForTenant(to, () => next('/'))
    }
  }
  next() // make sure to always call next()!
})


async function loadConfig () {
  const config = store.state.app.config
  if (!config) {
    await store.dispatch('loadConfig')
  }
}

function checkIsRouteAllowedForTenant (route, fallback) {
  const { isMainTenant } = store.state.app.config.tenant
  const { onlyMainTenant } = route.meta
  if (onlyMainTenant && !isMainTenant) {
    return fallback()
  }
}

function formatErrorMessages (response) {
  const errors = response?.body?.errors || []
  return errors.reduce((acc, { propertyPath, message }) => {
    return acc += `<p style="line-height: 1.2em; margin-top: 0.5em"><b>${propertyPath}</b>: ${message}</p>`
  }, '')
}

Vue.http.interceptors.push((request, next) => {
  next((response) => {
    if (response.status === 403 && has(response.body, 'loggedIn') && !response.body.loggedIn) {
      store.dispatch('setCurrentUser', { isAuthenticated: false, user: null })

      if (!isUndefined(request.silent) && !request.silent) {
        router.replace({
          path: '/login',
          query: { redirect: router.currentRoute.fullPath, autologin: 1 }})
      }

      return response
    }

    if (!isUndefined(request.silent) && request.silent) {
      return response
    }

    if (request.method !== 'GET' && [201, 204].indexOf(response.status) > -1) {
      Notification({
        message: request.method === 'DELETE' ? 'Удалено' : 'Сохранено',
        type: 'success',
        duration: 3000
      })
      return response
    } else if (response.status < 400) {
      return response
    } else {
      Notification({
        title: `Ошибка (${response.status})`,
        message: `
            <div style="text-align: left;">
              <p>${response.body.message ? response.body.message : response.statusText}</p>
              <p style="margin-top: 0.5em; margin-bottom: 0.5em"><b>RequestId</b>: ${response.headers.get('request-id')}</p>
              ${formatErrorMessages(response)}
            </div>
          `,
        dangerouslyUseHTMLString: true,
        type: 'error',
        duration: 5000
      })
      return response
    }
  })
})

// auth
Vue.use(Acl, () => store.getters.currentUser, AclInstance, { router })

// mask
Vue.use(VueMask)

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: "ru",
  messages: locales
})

const app = new Vue({
  i18n,
  router,
  store,
  vuetify,
  nprogress: new NProgress({ parent: '.nprogress-container' }),
  render: h => h(App)
})

export { app, router, store }
