<template>
  <v-form>
    <validation-provider
      v-for="select in selects"
      :key="select.name"
      :rules="select.rules"
      v-slot="v"
    >
      <div>
        <booking-input
          v-model="form[select.name]"
          :name="select.name"
          :item-text="select.labelField"
          :item-value="select.valueField"
          :placeholder="select.placeholder"
          :load="select.load"
          prepend-inner-icon="mdi-magnify"
          return-object
          clearable
          hide-no-data
          :error="!!v.failedRules.required"
          :hint="v.failedRules.required ? 'Обязательно выберите услугу' : ''"
          persistent-hint
          outlined
        />
      </div>
    </validation-provider>
  </v-form>
</template>

<script>
import doctors from '../../../services/doctors'
import clinics from '../../../services/clinics'
import services from '../../../services/service'
import BookingInput from './EmbedBookingInput.vue'
import { EmbedBookingLink } from '../../../models/EmbedBooking'

export default {
  components: {
    BookingInput
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      form: new EmbedBookingLink(this.value || {})
    }
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler (form) {
        this.$emit('input', form)
      }
    }
  },
  computed: {
    selects () {
      return [
        {
          name: 'service',
          placeholder: 'Услуга',
          labelField: 'name',
          valueField: 'id',
          load: (search) => services.getAll({ search, isPublic: false }).then(({ items }) => items),
          rules: 'required'
        },
        {
          name: 'clinic',
          placeholder: 'Клиника',
          labelField: 'name',
          valueField: 'id',
          load: (search) => clinics.getAll({ search, limit: 50 }).then(({ items }) => items),
          rules: ''
        },
        {
          name: 'doctor',
          placeholder: 'Доктор',
          labelField: 'fullName',
          valueField: 'id',
          load: (search) => doctors.getAll({ search, limit: 50 }).then(({ items }) => items),
          rules: ''
        }
      ]
    }
  },
}
</script>
