import vuetify from './plugins/vuetify'
import Vue from 'vue'
import Quill from 'components/elements/quilljs/bootstrap'
import {
  Button, Select, Table, TableColumn, Tabs, TabPane, Loading, MessageBox, Notification,
  Message, Pagination, FormItem, Form, Option, Input, Row, Col, Alert, Tag, DatePicker, TimePicker
} from 'element-ui'
import localeElementUIRu from 'element-ui/lib/locale/lang/ru-RU'
import localeElementUI from 'element-ui/lib/locale'
import Multiselect from "vue-multiselect"
import InputMask from 'vue-input-mask'
import { VueEditor } from 'vue2-editor'
import components from './components'
import photoModal from "./mixins/photoModal"
import helpersMixin from './mixins/helpers'
import ImageRequirements from '@/components/elements/image-requirements.vue'
import VPrice from '@/components/VPrice'

import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate'
import validateRu from 'vee-validate/dist/locale/ru.json'
import * as rules from 'vee-validate/dist/rules'
import VueYandexMetrika from 'vue-yandex-metrika'

import UIElements from './components/elements'
import Vuelidate from 'vuelidate'
import Resource from 'vue-resource'
import NProgress from 'vue-nprogress'
import VueClip from 'vue-clip'
import VueScrollTo from 'vue-scrollto'
import VueClipboard from 'vue-clipboard2'
import money from 'v-money'
import * as filters from './filters'
import 'vue-cropperjs'

export default class Bootstrap {
  static i18n() {
    localeElementUI.use(localeElementUIRu)
  }
  static validator() {
    localize(validateRu)
    Object.keys(rules).forEach(rule => {
      extend(rule, rules[rule]);
    })
    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)
  }
  static components() {
    Quill.init()

    Vue.component(ImageRequirements.name, ImageRequirements)
    Vue.component(Button.name, Button)
    Vue.component(Select.name, Select)
    Vue.component(Table.name, Table)
    Vue.component(TableColumn.name, TableColumn)
    Vue.component(Pagination.name, Pagination)
    Vue.component(Tabs.name, Tabs)
    Vue.component(TabPane.name, TabPane)
    Vue.component(FormItem.name, FormItem)
    Vue.component(Form.name, Form)
    Vue.component(Option.name, Option)
    Vue.component(Input.name, Input)
    Vue.component(Row.name, Row)
    Vue.component(Col.name, Col)
    Vue.component(Alert.name, Alert)
    Vue.component(Tag.name, Tag)
    Vue.component(DatePicker.name, DatePicker)
    Vue.component(TimePicker.name, TimePicker)
    Vue.component(VPrice.name, VPrice)

    Vue.prototype.$loading = Loading.service
    Vue.prototype.$msgbox = MessageBox
    Vue.prototype.$alert = MessageBox.alert
    Vue.prototype.$confirm = MessageBox.confirm
    Vue.prototype.$prompt = MessageBox.prompt
    Vue.prototype.$notify = Notification
    Vue.prototype.$message = Message

    Vue.component('multiselect', Multiselect)
    Vue.component('input-mask', InputMask)
    Vue.component('vue-editor', VueEditor)

    for (let name in components) {
      Vue.component(name, components[name])
    }

    Object.keys(UIElements).forEach(key => {
      Vue.component(key, UIElements[key])
    })
  }
  static plugins({ router }) {
    Vue.use(Loading.directive)
    Vue.use(Vuelidate)
    Vue.use(Resource)
    Vue.use(NProgress)
    Vue.use(VueClip)
    Vue.use(VueScrollTo)
    Vue.use(VueClipboard)
    Vue.use(VueYandexMetrika, {
      id: '90377666',
      router,
      env: process.env.NODE_ENV,
      debug: false //process.env.NODE_ENV === 'development'
    })
    Vue.use(money, {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 2,
      masked: true
    })
  }
  static filters() {
    Object.keys(filters).forEach(key => {
      Vue.filter(key, filters[key])
    })
  }
  static mixins() {
    Vue.mixin(photoModal)
    Vue.mixin(helpersMixin)
  }
}
