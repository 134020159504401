import BaseService from './baseService'

const GRAVITIES = [
  'nowe', 'no', 'noea',
  'we', 'ce', 'ea',
  'sowe', 'so', 'soea'
]
const SMART_GRAVITY = 'sm'

class PhotoService extends BaseService {
  get url () {
    return 'photos'
  }
  get gravities () {
    return GRAVITIES
  }
  get smartGravity () {
    return SMART_GRAVITY
  }
}

export default new PhotoService()

